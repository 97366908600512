<template>
<div>
  <v-row class="option d-flex align-center distance-option">
    <v-col cols="7" md="9">
      <div ref="descriptiontext" class="descriptiontext">
        <h4 class="text-h6" color="primary"><a class="icon-link" data-bs-toggle="modal" data-bs-target="#thermeon-webres-distance" :data-bs-country="country" :data-bs-unit="mileOrKM" :data-bs-distance="distance" :data-bs-currency="currency" :data-bs-rate="distanceRate" :data-bs-total="distanceTotal" :data-bs-nights="rentalDuration">Estimated Trip Distance <v-icon small color="primary">mdi-information</v-icon></a></h4>
      </div>

      <v-row no-gutters>
        <v-col class="mt-4 mr-2 shrink nowrap">
          {{ label }}
        </v-col>
        <v-col cols="4" class="mt-2" >
          <NumberInput :label="label" v-model="distance" v-bind:min="minDistance"></NumberInput>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="text-right text-h6">
      {{ distanceTotal | price }}
    </v-col>
  </v-row>
  <v-row >
    <v-col class="font-italic disclaimer">
      Please enter the estimated distance traveled during your trip. Distance will be charged at {{ distanceRate }}¢ per mile.
    </v-col>
  </v-row>
</div>
</template>
<script>

import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import NumberInput from '../components/NumberInput.vue'

export default {
  name: 'DistanceOption',
  components: {
    NumberInput
  },
  props: {
    value: Number
  },
  computed: {
    ...mapState({
      locations: state => state.config.rentingLocations,
      reservation: state => state.reservation.reservation,
    }),
    ...mapGetters({
      getDuration: 'rates/getDuration',
    }),
    mileOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "mile"
      }
    },
    milesOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "miles"
      }
    },
    label (){
      return `Total ${this.milesOrKM}`
    },
    distanceRate () {
      if (!this.reservation.Rate.Rental){
        return 35;
      }
      return this.reservation.Rate.Rental.Distance.ExtraRate * 100
    },
    distanceTotal () {
      return Math.round((this.distance - this.includedMiles) * (this.distanceRate / 100))
    },
    location () {
      if (!this.reservation.Pickup) return
      let locationCode = this.reservation.Pickup.Location
      return this.locations.find(el=>{
        return el.LocationNumber == locationCode
      })
    },
    rentalDuration () {
      return this.getDuration
    },
    includedMiles (){
      if (!this.reservation.Rate.Rental) return 0
      return this.reservation.Rate.Rental.Distance.Included || 0
    },
    minDistance (){
      return this.includedMiles || 50
    },
    country () {
      if (!this.location) return ''
      return this.location.CountryCode
    },
    currency () {
      let currency = 'USD';
      if (this.country === 'CA'){
        currency = 'CAD';
      }
      return currency
    },
  },
  methods: {
    ...mapMutations({
      updateReservation: 'reservation/updateReservation',
    }),
    ...mapActions({
      getEstimate: 'reservation/getEstimate',
    }),
  },
  watch: {
    value(v){
      this.distance = v
    },
    distance () {
      // Set the estimated milage on the res
      this.updateReservation({ EstimatedDistance: this.distance })
      this.getEstimate()
    },
  },
  data () {
    return {
      option: {
        Code: 'MILES'
      },
      distance: this.value
    }
  },
};
</script>