<template>
  <v-col class="estimate" v-if="this.estimate.Customer.Total">
    <h2 class="text-h4 mb-6">Estimate</h2>
    <v-row>
      <v-col cols="8">{{ duration }} Nights</v-col>
      <v-col class="text-right text-h6">{{ estimate.Customer.TimeCharges[0].SubTotal | price }}</v-col>
    </v-row>
    <v-row v-if="showDistance">
      <v-col cols="8">{{ distanceEstimated }} Estimated {{ distanceUnit }}</v-col>
      <v-col class="text-right text-h6">{{ distanceTotal | price }}</v-col>
    </v-row>

    <v-row v-for="option in filteredOptions" v-bind:key="option.Code">
      <template v-if="(option.Quantity > 1)">
        <v-col cols="4">{{ option.Description | sentencecaps }}</v-col>
        <v-col cols="4">x {{ option.Quantity }}</v-col>
      </template>
      <template v-else>
        <v-col cols="8" class="description">{{ option.Description | sentencecaps }}</v-col>
      </template>
      <v-col class="text-right text-h6">
        <template v-if="(option.Total !== 0)">{{ option.SubTotal | price }}</template>
        <template v-else>No Charge</template>
      </v-col>
    </v-row>

    <v-row class="mb-1 px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row>
      <v-col>Sub Total</v-col>
      <v-col class="text-right text-h6">{{ preTaxSubTotal | price }}</v-col>
    </v-row>

    <v-row v-for="charge in combinedTaxCharges" v-bind:key="charge.Code">
      <v-col cols="8" class="description">{{ charge.Description | sentencecaps }}</v-col>
      <v-col class="text-right text-h6">
        <template v-if="charge.Total">{{ charge.Total | price }}</template>
        <template v-else>No Charge</template>
      </v-col>
    </v-row>

    <v-row class="mb-1 px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row >
      <v-col cols="8" class="text-h6">Total Cost of Rental</v-col>
      <v-col class="text-right text-h6">{{ totalCostOfRental | price }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="">Refundable Security Deposit (Due at Pick Up)</v-col>
      <v-col class="text-right text-h6">{{ 500 | price }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="">Reservation Downpayment ({{ dueOrPaid }})</v-col>
      <v-col class="text-right text-h6">{{ -300 | price }}</v-col>
    </v-row>

    <v-row class="px-3">
      <v-divider role="presentation"></v-divider>
    </v-row>

    <v-row class="pt-2">
      <v-col cols="8" class="text-h6">Total Due at Pick Up</v-col>
      <v-col class="text-right text-h6">{{ totalDueAtPickUp | price }}</v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'EstimateSummary',
    props: {
      createdRes: String
    },
    computed: {
    ...mapState({
      loading: 'loading',
      locations: state => state.config.rentingLocations,
      estimate: state => state.reservation.estimate,
      reservation: state => state.reservation.reservation,
    }),
    ...mapGetters({
      showDistance: 'showDistance',
      preTaxSubTotal: 'reservation/preTaxSubTotal',
      duration: 'reservation/duration',
    }),
    filteredTimeCharges () {
      return this.estimate.Customer.TimeCharges.filter(charge => charge.Total)
    },
    totalCostOfRental (){
      // the post-tax cost minus $500 security deposit
      if (!this.estimate.Customer.Total){
        return 0
      }
      return this.estimate.Customer.Total - 500
    },
    totalDueAtPickUp () {
      if (this.estimate.Customer.Total){
        return this.estimate.Customer.Total - 300
      }
      return 0
    },
    filteredOptions () {
      return this.estimate.Customer.Options.filter(charge => {
        // hide cruise-specific hack.
        if (charge.Code === 'SLI'){
          charge.Total = 0
          return true
        }
        else {
          return charge.Code !== 'PROM0' && charge.Code !== 'SECURITY' && charge.Total && charge.IncludeInSubTotal === true
        }
      })
    },
    combinedTaxCharges () {
      // combine the tax charges with options that have 'IncludeInSubTotal' flag
      let optionsIncludedInTaxCharges = this.estimate.Customer.Options.filter(charge => {
        return !charge.IncludeInSubTotal && charge.Code !== 'SLI' && charge.Code !== 'PROM0' && charge.Quantity !== undefined
      })
      if (!this.estimate.Customer.TaxCharges){
        return optionsIncludedInTaxCharges
      }
      return this.estimate.Customer.TaxCharges.concat(optionsIncludedInTaxCharges)
    },
    pickupLocation (){
      if (!this.reservation.Pickup){
        return {};
      }
      let pickupLocCode = this.reservation.Pickup.Location;
      return this.locations.find(loc =>{
        return loc.LocationNumber == pickupLocCode
      })
    },
    dueOrPaid () {
      if (this.reservation.ReservationStatus === "new"){
        return 'Due Today'
      }
      else {
        return 'Paid'
      }
    },
    distanceUnit () {
      if (this.pickupLocation.CountryCode === 'CA'){
        return 'Kilometers';
      }
      return 'Miles';
    },
    distanceRate () {
      if (this.reservation.Rate.Rental && this.reservation.Rate.Rental.Distance){
        return this.reservation.Rate.Rental.Distance.ExtraRate
      }
      return 0;
    },
    distanceEstimated () {
      return this.reservation.EstimatedDistance;
    },
    distanceIncluded (){
      if (this.reservation.Rate.Rental && this.reservation.Rate.Rental.Distance){
        return this.reservation.Rate.Rental.Distance.Included || 0
      }
      return 0;
    },
    distanceTotal () {
      return (this.distanceEstimated - this.distanceIncluded) * this.distanceRate
    },
    distanceLink () {
      let country = this.pickupLocation.CountryCode;

      let currency = 'USD';
      if (country === 'CA'){
        currency = 'CAD';
      }
      let rate = this.estimate.Customer.Distance.RateExclTax;
      let unit = this.estimate.Customer.Distance.Unit;
      let distance = this.distanceEstimated;
      let duration = this.duration;
      return `https://www.cruiseamerica.com/rent/webres/miles?country=${country}&currency=${currency}&rate=${rate}&unit=${unit}&distance=${distance}&nights=${duration}`;
    },
  },
  methods: {
    popup (event) {
      let href = event.currentTarget.href
      window.open(href, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=480,height=300')
    },
  },
  filters: {
    sentencecaps: function (value) {
      if (!value) return ''
      let sentence = value.toString().toLowerCase().split(' ')
      .map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1))
      })
      return sentence.join(' ')
    }
  },
};

</script>
<style>
.estimate .col {
  padding-top: 0px;
  padding-bottom: 7.5px;
}
</style>