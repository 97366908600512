import Vue from 'vue'
import Vuex from 'vuex'

/* store modules */
import config from './modules/config'
import locations from './modules/locations'
import reservation from './modules/reservation'
import rates from './modules/rates'
import options from './modules/options'

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    config: config,
    locations: locations,
    reservation: reservation,
    rates: rates,
    options: options,
  },

  state: {
    errors: [],
    loading: false,
    seenOptions: false,
  },

  getters: {
    pickupLocation (state){
      let loccode = state.reservation.reservation.Pickup.Location
      if (!loccode){
        loccode = state.rates.rateQuery.Pickup.Location
      }
      let loc = state.config.rentingLocations.find(el=>{
          return el.LocationNumber == loccode
      })
      if (!loc){
        return {
          LocationNumber: "",
          Description: "",
          StateCode: "",
          CountryCode: "US",
          Currency: "USD",
        }
      }
      return loc
    },
    vehicleClassTitle (state){
      var desc = state.config.vehicleDescriptions[state.reservation.reservation.RateClass];
      if (desc) {
        return desc.Title
      }
      return ""
    },
    showDistance (state){
      // hide distance on trailers
      if (state.reservation.reservation.Rate.Rental.Distance.ExtraRate === 0 || state.reservation.reservation.RateClass.indexOf('TR') === 0){
        return false
      }
      return true
    },
  },

  mutations: {
    setErrors (state, error){
      // Normalise errors into an array of strings
      // Make sure every message is numbered for translation
      if (error.response && error.response.data){
        // Error object is an Array
        if (Array.isArray(error.response.data)) {
          for (var err of error.response.data){
            if (!err.Number) {
              err.Number = error.response.status
            }
            if (err.Text) {
              state.errors.push(err);
            }
          }
        }
        // Error object is one object
        else {
          if (error.response.data.message){
            state.errors.push({
              Number: error.response.data.code || error.response.status,
              Text: error.response.data.message
            });
          }
        }
      }
    },
    clearErrors (state){
      state.errors = [];
    },
    setLoading (state, value){
      state.loading = value
    },
    setSeenOptions (state, value){
      state.seenOptions = value
    },
  },
  actions: {
  }
})