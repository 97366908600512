<template>
  <section class="vehicle-class">
  <h2 class="text-h4 mb-6">{{ vehicle.Title }}</h2>

  <v-row>
    <v-col lg="7" md="7" cols="12">
      <v-carousel height="340" hide-delimiters :class="[vehicle.Description ? 'hascaption' : '', 'vehcarousel']" aria-hidden="true">
        <v-carousel-item
          v-for="(item, i) in vehicle.Images"
          :key="i"
          :src="item.src"
          :alt="item.Name">
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col>

      <ul class="bullets text-subtitle-2">

        <li v-if="vehicle.Passengers">
          Sleeps: {{ vehicle.Passengers }}
        </li>

        <li v-if="generator">
          Generator
        </li>

        <li>
          Gas Cooktop
        </li>

        <li>
          Refrigerator
        </li>

        <li>
          Microwave
        </li>

        <li>
          Toilet
        </li>

        <li>
          Shower
        </li>

        <li v-if="vehicle.Storage">
          <span v-html="vehicle.Storage"></span>
        </li>

      </ul>

    </v-col>
  </v-row>
  </section>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'VehicleDetails',
  computed: {
    ...mapState({
      vehicleDescriptions: state => state.config.vehicleDescriptions,
      reservation: state => state.reservation.reservation,
      locations: state => state.config.rentingLocations
    }),
    ...mapGetters({
      pickupLocation: 'pickupLocation',
    }),
    vehicle () {
      var desc = this.vehicleDescriptions[this.rate.Class];
      if (!desc){
        // return a default desc object
        return {
          Title: this.rate.Class,
          Images: ['vehicle.png'],
          Description: '',
          Generator: {
            "CA": false,
            "US": false
          }
        };
      }
      else {
        return desc;
      }
    },
    generator () {
      // Generator varies by country
      let country = this.pickupLocation.CountryCode
      return this.vehicle.Generator[country]
    }
  },
  props: {
    rate: {
      Class: String,
      Availability: String,
      Rental: {
        Distance: {
          ExtraRate: Number,
          Unit: String
        },
        Estimate: Number,
        RateOnlyEstimate: Number
      }
    }
  }
};
</script>
<style>
.vehcarousel {
  border-radius: 10px;
  position: relative;
}
.carousel-caption {
  background: #345c05;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding: 1em;
  height: auto;
  width: 100%;
  line-height: 1.5em;
  position: absolute;
  bottom: 0;
}
.hascaption .v-image__image--cover {
background-position-y: -50px !important;
}
</style>