<template>
  <div class="d-flex align-center">
    <v-btn icon @click="prevClass" aria-label="Previous Vehicle Class (content will update below)">
      <v-icon large>mdi-menu-left</v-icon>
    </v-btn>
    <v-chip outlined label class="uppercase font-weight-bold d-flex justify-center" :class="{'grow': $vuetify.breakpoint.smAndDown}">
      {{ currentClassTitle }}
    </v-chip>
    <v-btn icon @click="nextClass" aria-label="Next Vehicle Class (content will update below)">
      <v-icon large>mdi-menu-right</v-icon>
    </v-btn>
  </div>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'ClassSwitch',
  props: {
    currentMonth: String
  },
  computed: {
    ...mapState({
      rateQuery: state => state.rates.rateQuery,
      vehicleDescriptions: state => state.config.vehicleDescriptions,
      currentClass: state => state.rates.flexRatesClass
    }),
    currentClassTitle () {
      var desc = this.vehicleDescriptions[this.currentClass];
      if (desc) {
        return desc.Title
      }
      return ""
    },
  },
  methods: {
    ...mapMutations({
      setFlexRateClass: 'rates/setFlexRateClass',
    }),
    prevClass () {
      let index = this.vehClassList.indexOf(this.currentClass)
      index--
      if (index < 0){
        index = this.vehClassList.length-1
      }
      let newClass = this.vehClassList[index]
      this.updateRateClass(newClass)
    },
    nextClass () {
      let index = this.vehClassList.indexOf(this.currentClass)
      index++
      if (index === this.vehClassList.length){
        index = 0
      }
      let newClass = this.vehClassList[index]
      this.updateRateClass(newClass)
    },
    updateRateClass (newClass){
      this.setFlexRateClass(newClass)
      this.$store.dispatch('rates/getMonthOfRates', this.currentMonth)
    },
  },
  data () {
    return {
      vehClassList: ["C30","C25","C21","C19","TR16"]
    }
  }
};
</script>