<template>
  <v-container>
    <ItineraryComponent v-bind:aria-hidden="showError ? true : false"></ItineraryComponent>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ItineraryComponent from '../components/ItineraryComponent.vue'

export default {
  name: 'ItineraryPage',
  components: {
    ItineraryComponent
  },
  computed: {
    ...mapState({
      errors: 'errors',
    }),
    showError () {
      // Convert error array into bool
      return !!this.errors.length;
    },
  },
}

</script>
