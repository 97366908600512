<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="First Name" v-model.lazy="Contact.FirstName" required :rules="FirstNameRules" autocomplete="given-name"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="Last Name" v-model.lazy="Contact.LastName" required  :rules="LastNameRules" autocomplete="family-name"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="Phone" v-model.lazy="Contact.Address.CellTelephoneNumber" :rules="TelephoneNumberRules" required autocomplete="tel"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="Email" v-model.lazy="Contact.Address.Email" :rules="EmailRules" required autocomplete="email"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="Confirm Email" :rules="ConfirmEmailRules" required autocomplete="email"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>

export default {
    name: 'Contact',
    props: {
      Contact: {
        type: Object
      }
    },
    data () {
      return {
        FirstNameRules: [
          v => !!v || 'First Name is required'
        ],
        LastNameRules: [
          v => !!v || 'Last Name is required'
        ],
        TelephoneNumberRules: [
          v => !!v || 'Phone Number is required',
          v => /^[0-9-+()*#]+$/.test(v) || 'Invalid characters in phone number'
        ],
        EmailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        ConfirmEmailRules: [
          v => !!v || 'E-mail confirmation is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
          v => v && v.toLowerCase() === this.Contact.Address.Email.toLowerCase() || 'E-mail must match'
        ]
      }
    }
};

</script>
<style>
</style>
