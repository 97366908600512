<template>
  <div class="d-flex align-center justify-end">
    <v-btn icon @click="prev" aria-label="Previous Month (content will update below)">
      <v-icon large>mdi-menu-left</v-icon>
    </v-btn>
    <v-chip outlined label class="uppercase font-weight-bold">
      {{ currentMonth }}
    </v-chip>
    <v-btn icon @click="next" aria-label="Next Month (content will update below)">
      <v-icon large>mdi-menu-right</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'MonthSwitch',
  props: {
    currentMonth: String
  },
  methods:{
    next(){
      this.$emit('next')
    },
    prev(){
      this.$emit('prev')
    },
  },
}
</script>