<template>
<div class="webres" style="display: inline;">
  <v-app v-bind:class="{ 'itinerary': $route.path == '/', 'showerror':showError }">
    <v-main>

      <router-view v-bind:aria-hidden="showError ? true : false"></router-view>

      <div v-if="showError">
        <v-card max-width="400" class="mx-auto">
          <v-card-title class="headline"><v-icon color="red" left>error_outline</v-icon> <h2 class="headline">Error</h2></v-card-title>
          <v-card-text>
            <p v-for="error in translatedErrors" v-bind:key="error.Number">
              {{ error.Text }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="clearErrors()" ref="dismissError">OK</v-btn>
          </v-card-actions>
        </v-card>
      </div>

    </v-main>

  </v-app>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapState({
      errors: 'errors',
      errorMessages: state => state.config.errorMessages,
      loading: 'loading',
      reservation: state => state.reservation.reservation,
      seenOptions: 'seenOptions'
    }),
    showError () {
      // Convert error array into bool
      return !!this.errors.length;
    },
    translatedErrors (){
      const errorArray = []
      const seenErrors = []
      for (let i in this.errors){
        let error = this.errors[i]
        let translatedError = this.errorMessages[error.Number.toString()]
        if (translatedError && translatedError.Text){
          error.Text = translatedError.Text
        }
        // avoid duplicates
        if (seenErrors.indexOf(error.Number) === -1){
          seenErrors.push(error.Number)
          errorArray.push(error)
        }
      }
      return errorArray
    },
  },
  data () {
      return {
        step: 1,
      }
  },
  methods: {
    clickStep(route){
      this.$router.push({path: `/${route}`})
    },
    clearErrors () {
      this.$store.commit("clearErrors");
    },
    logAnalytics(page){
      // send details to google analytics tag manager
      if (window.dataLayer){
        let pageTitle = page.path
        window.dataLayer.push({
          'event': 'page_view',
          'page_location': window.location.href,
          'page_title': pageTitle,
        })
      }
    },
  },
  watch:{
    $route (to){
      // Update the step with the route
      this.step = to.meta.step;

      this.logAnalytics(to);
    },
  },
  created() {
    // get static files for option/rate/vehicle details
    this.$store.dispatch("config/errorMessages")
    this.$store.dispatch("config/vehicleDescriptions")
    this.$store.dispatch("config/getOptionDetails")
    // get locations
    this.$store.dispatch("config/rentingLocations", { self: this })
  },
  mounted() {
    // workaround HTML validation error
    // https://github.com/vuetifyjs/vuetify/issues/14615
    const removeAriaReadonly = function(input){
      const ariareadonly = input.attributes.getNamedItem("aria-readonly")
      const readonly = input.attributes.getNamedItem("readonly")
      if (ariareadonly && readonly){
        // remove redundant readonly property
        input.removeAttribute("aria-readonly")
      }
    }
    const swapRoles = function(el){
      const role = el.attributes.getNamedItem("role")
      if (role && role.value == 'button'){
        // use role 'combobox' instead of 'button'
        el.setAttribute("role", "combobox")
      }
    }
    // run on each future mutation
    const callback = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const element = mutation.target
        const inputs = element.querySelectorAll("input")
        inputs.forEach(removeAriaReadonly)
        const inputslots = element.querySelectorAll(".v-select .v-input__slot")
        inputslots.forEach(swapRoles)
      })
    })
    const content = this.$root.$el.querySelector(".v-main")
    callback.observe(content, {
      subtree: true,
      attributes: true,
    })
    // run now on elements already mutated at mount time
    this.$el.querySelectorAll("input").forEach(removeAriaReadonly)
    this.$el.querySelectorAll(".v-select .v-input__slot").forEach(swapRoles)

  },
};
</script>
<style>
.itinerary {
  min-height: auto;
}
.showerror {
  min-height: 400px;
}
</style>