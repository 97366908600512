<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="Street Address" v-model.lazy="Address.Street" required :rules="Street1Rules" autocomplete="address-line1"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="City" v-model.lazy="Address.City" required :rules="CityRules" autocomplete="address-line2"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete solo outlined dense hide-details :items="addressStates" item-text="Name" item-value="Code" v-model.lazy="Address.StateOrProvCode" autocomplete="address-level1" attach>
        <template v-slot:label>
          State / Province <span class="d-sr-only">Use the up and down arrows or start typing, then use the up and down arrows to select an option from the list</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field solo outlined dense hide-details label="ZIP Code" v-model.lazy="Address.PostalCode" required :rules="ZipRules" autocomplete="postal-code"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete solo outlined dense hide-details :items="countries" item-text="Name" item-value="Code" v-model.lazy="Address.CountryCode" autocomplete="country" attach>
        <template v-slot:label>
          Country <span class="d-sr-only">Use the up and down arrows or start typing, then use the up and down arrows to select an option from the list</span>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Address',
    props: {
      type: {
        type: String,
        default: 'billing'
      },
      Address:  {
        type : Object
      }
    },
    computed: {
      ...mapState({
        addressStates: state => state.config.addressStates,
        countries: state => state.config.countries,
      }),
    },
    data () {
      return {
        Street1Rules: [
          v => !!v || 'Address Line 1 is required'
        ],
        CityRules: [
          v => !!v || 'City is required'
        ],
        ZipRules: [
          v => !!v || 'Zip Code is required'
        ],
      }
    },
    created() {
      this.$store.dispatch("config/addressStates", { self: this })
      this.$store.dispatch("config/countries", { self: this })
    }
};

</script>
<style>
</style>
