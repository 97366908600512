<template>
  <v-row class="option d-flex align-center">
    <v-col cols="7" md="9" no-gutters>
      <div ref="descriptiontext" class="descriptiontext">
        <h4 class="text-h6" color="primary" :id="option.Code"><a class="icon-link" data-bs-toggle="modal" :data-bs-target="`#thermeon-webres-${details.popup}`">{{ details.Title }} <v-icon small color="primary">mdi-information</v-icon></a></h4>
      </div>

      <div v-if="!option.AllowQuantity">
        <v-checkbox solo dense :id="`${option.Code}-c`" label="Add" type="checkbox" v-model="checkboxValue" :aria-labelledby="labelledBy">
          <template v-slot:label :aria-labelledby="labelledBy">
            {{ details.CustomPriceString }}
          </template>
        </v-checkbox>
      </div>

      <v-row no-gutters>
        <v-col cols="4" class="mt-2" v-if="option.AllowQuantity">
          <NumberInput v-model="Quantity" label="Quantity" v-bind:min="0" v-bind:max="999" :steppers="true"></NumberInput>
        </v-col>
        <v-col class="mt-4 mx-2 shrink" v-if="option.AllowQuantity">
          {{ details.CustomPriceString }}
        </v-col>
      </v-row>
    </v-col>

    <v-col class="price text-right text-h6" no-gutters>
      {{ estimatedOptionPrice | price }}
    </v-col>

  </v-row>
</template>
<script>

import { mapState } from 'vuex'
import NumberInput from '../components/NumberInput.vue'

export default {
  name: 'Options',
  components: {
    NumberInput
  },
  props: {
    option: {
      Code: String,
      Description: String,
      AllowQuantity: Boolean,
      Quantity: Number,
      CurrencyCode: String,
      DamageLiability: String,
      SubTotal: Number,
      Total: Number,
      Tax: Number
    }
  },
  computed: {
    ...mapState({
      optionDetails: state => state.config.optionDetails,
      estimate: state => state.reservation.estimate,
    }),
    details () {
      let detail = this.optionDetails[this.option.Code];

      if (!detail){
        // return a default set if not configured
        return {
          ImageURL: 'options_images/option.gif',
          Description: this.option.Description,
          Title: this.option.Code
        };
      }
      else {
        return detail;
      }
    },
    labelledBy(){
      return `${this.option.Code} ${this.option.Code}-c`
    },
    estimatedOptionPrice(){
      let optionCode = this.option.Code
      if (optionCode && this.estimate && this.estimate.Customer && this.estimate.Customer.Options){
        let estimatedOption = this.estimate.Customer.Options.find(el=>{
          return el.Code == optionCode
        })
        if (estimatedOption){
          return estimatedOption.SubTotal
        }
      }
      return 0
    },
  },
  methods: {
    increment () {
      this.Quantity = parseInt(this.Quantity, 10) + 1;
    },
    decrement () {
      this.Quantity = parseInt(this.Quantity,10) - 1;
    },
  },
  watch: {
    Quantity () {
      // Send the quantity to the parent
      let option = {
        Code: this.option.Code,
        Quantity: this.Quantity
      }
      this.$emit('select-option', option);
    },
    checkboxValue () {
      if (this.checkboxValue){
        this.Quantity = 1;
      }
      else {
        this.Quantity = 0;
      }
    }
  },
  data () {
    return {
      Quantity: this.option.Quantity || 0,
      checkboxValue: !!this.option.Quantity
    }
  },
};
</script>
<style>
  .descriptiontext a {
    text-decoration: none;
  }
  .descriptiontext .v-icon {
    margin-top: -2px;
  }
  .descriptiontext .text-h6 {
    font-size: 1.1rem;
  }
</style>