<template>

  <span class="trip-details ">
    <h2 class="mt-16 mb-10 text-h4">Trip Details</h2>

    <v-row>
      <v-col cols="12">
        <v-banner color="secondary lighten-5" rounded class="uppercase subtitle-1 text-center my- elevation-0" elevation="0">
          Call Pickup Location {{ pickupPhone }} for departure arrangements prior to arrival.
        </v-banner>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-sheet color="grey lighten-5" rounded class="px-6 pt-4 pb-6">
          <h3 class="text-h5 mb-4">Pick Up</h3>
          <dl>
            <dt class="font-weight-bold">Location</dt>
            <dd><address v-html="pickupAddress"></address></dd>

            <dt class="font-weight-bold mt-4">Date/Time</dt>
            <dd>{{ reservation.Pickup.DateTime | moment("MMMM Do YYYY") }} {{ pickupTimeString }}</dd>
          </dl>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet color="grey lighten-5" rounded class="px-6 pt-4 pb-6">
          <h3 class="text-h5 mb-4">Drop Off</h3>
          <dl>
            <dt class="font-weight-bold">Location</dt>
            <dd><address v-html="pickupAddress"></address></dd>

            <dt class="font-weight-bold mt-4">Date/Time</dt>
            <dd>{{ reservation.Return.DateTime | moment("MMMM Do YYYY") }} before 11:00 AM</dd>
          </dl>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="6" class="px-9 pt-8">

        <ul class="no-bullets ">
          <li v-if="reservation.ReservationStatus !== 'open' && reservation.ReservationNumber">
            <span class="font-weight-bold">Status:</span> {{ reservation.ReservationStatus }}
          </li>
          <li v-if="reservation.ReservationNumber">
            <span class="font-weight-bold">Confirmation Number:</span> {{ reservation.ReservationNumber }}
          </li>
          <li>
            <span class="font-weight-bold">RV Type:</span> {{ vehicleClassTitle }}
          </li>
          <li>
            <span class="font-weight-bold">Number of Passengers:</span> {{ reservation.Passengers }}
          </li>
          <li v-if="reservation.PackageCode">
            <span class="font-weight-bold">Deal Code:</span> {{ reservation.PackageCode }}
          </li>
          <li>
            <span class="font-weight-bold">Kitchen Kit:</span> {{ kitchenKits }}
          </li>
          <li>
            <span class="font-weight-bold">Personal Kit:</span> {{ personalKits }}
          </li>
          <li>
            <span class="font-weight-bold">Total {{ distanceUnit }}s included with rental*:</span> {{ includedDistance }}
          </li>
        </ul>
        <p v-if="estimate.Customer.Distance.RateExclTax" class="font-italic">
        *Additional {{ distanceUnit }}s charged at {{ estimate.Customer.Distance.RateExclTax }} per {{ distanceUnit }}.
        </p>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TripDetails',
  computed: {
    ...mapState({
      locations: state => state.config.rentingLocations,
      reservation: state => state.reservation.reservation,
      estimate: state => state.reservation.estimate,
    }),
    ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
      pickupLocation: 'pickupLocation',
      vehicleClassTitle: 'vehicleClassTitle',
    }),
    pickupOpenHours () {
      let openings = this.getOpeningTimesForDate(this.reservation.Pickup.DateTime, this.reservation.Pickup.Location)
      if (openings && openings.length){
        return openings[0]
      }
      // fallback if openinghours does not return
      return {
        EndHour: '16:00'
      }
    },
    pickupDateTime () {
      let pickupDate = this.reservation.Pickup.DateTime.substring(0,11)
      return `${pickupDate}${this.pickupOpenHours.EndHour}`
    },
    pickupTimeString () {
      let time = this.$options.filters.moment(this.pickupDateTime, "h:mm A")
      let hour = parseInt(this.pickupOpenHours.EndHour.split(':')[0], 10)
      if (hour >= 16){
        return 'between 1:00 PM and 4:00 PM';
      }
      else {
        return `before ${time}`
      }
    },
    pickupAddress () {
      let p = this.pickupLocation;
      if (!p || !p.Address) return;
      return `${p.Address.Company}, ${p.Address.Street}, ${p.Address.CityState}, ${p.Address.Postal}`;
    },
    pickupPhone () {
      let p = this.pickupLocation;
      if (!p || !p.Address) return;
      return p.Address.Phone;
    },
    includedDistance (){
      // not the included distance but how many miles the user chose earlier
      return this.reservation.EstimatedDistance;
    },
    distanceUnit () {
      if (this.pickupLocation.CountryCode === 'CA'){
        return 'kilometer';
      }
      return 'mile';
    },
    kitchenKits () {
      let count = this.getOptionCount('KPK')
      if (count === 0){
        return 'No'
      }
      else {
        return 'Yes'
      }
    },
    personalKits () {
      let count = this.getOptionCount('KPP')
      if (count === 0){
        return 'No personal kits'
      }
      else {
        return `${count} personal kits`
      }
    },
  },
  methods: {
    getOptionCount (optionCode){
      let optionFromCode = this.reservation.Options.filter(function(option){
        if (option.Code === optionCode){
          return true;
        }
      });
      if (optionFromCode.length){
        return optionFromCode[0].Quantity;
      }
      else {
        return 0;
      }
    },
    optionPopup (event) {
      let href = event.currentTarget.href
      window.open(href, 'popup','scrollbars=yes,width=480,height=500')
    },
  }
};
</script>
<style>
.trip-details .col {
  padding-top: 0px;
  padding-bottom: 7.5px;
}
address {
  font-style: inherit;
}
</style>