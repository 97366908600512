<template>
  <div class="form-control flex-row relative">
    <div v-bind:class="['flex', 'w-full', 'px-4', 'relative', {'saparate-right': !flexibleRates}]">
      <div class="flex w-full flex-col pr-3">
        <span class="flex text-3xl font-extrabold text-primary">{{ pickupDate | moment("D") }}</span>
      </div>
      <div class="flex w-full flex-col items-center justify-center uppercase">
        <span class="flex text-sm leading-4 font-bold text-primary mb-0">{{ pickupDate | moment("MMM") }}</span>
        <span class="flex text-sm leading-4 font-bold text-primary">{{ pickupDate | moment("ddd") }}</span>
      </div>
    </div>
    <div class="flex w-full px-4 relative" v-if="!flexibleRates">
      <div class="flex w-full flex-col pr-3">
        <span class="flex text-3xl font-extrabold text-primary">{{ returnDate | moment("D") }}</span>
      </div>
      <div class="flex w-full flex-col items-center justify-center uppercase">
        <span class="flex text-sm leading-4 font-bold text-primary mb-0">{{ returnDate | moment("MMM") }}</span>
        <span class="flex text-sm leading-4 font-bold text-primary">{{ returnDate | moment("ddd") }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DateDisplay',
  props: {
    pickupDate: {
      type: String,
    },
    returnDate: {
      type: String,
    },
    flexibleRates: Boolean
  }
}
</script>
<style>
.day-number {
  float: left;
  height: 100%;
  font-size: 26px;
}
.month, .day-name {
  text-align: right;
  text-transform: uppercase;
  overflow: auto;
}
</style>