import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '../styles/overrides.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: '#345c05',
        secondary: '#235584',
        anchor: '#345c05',
        accent: '#3273AF',
        error: '#D7282D',
      },
    },
  },
})
export default vuetify