<template>
    <section class="component-center-content">
        <section class="container mx-auto">
            <section class="row">
                <section class="w-full text-center">
                    <section class="description">
                        <h2 class="text-3xl text-center text-neutral lg:text-7xl my-3" :title="title">
                          {{ title }}
                        </h2>
                        <section class="w-full text-center flex justify-center">
                            <ul class="items flex gap-2 my-8">
                                <li :class="{ active: selectActive }">
                                    <router-link :to="selectLink" tag="button" :disabled="!selectLink">
                                      SELECT RV
                                    </router-link>
                                </li>
                                <li :class="{ active: optionsActive }">
                                    <router-link :to="optionsLink" tag="button" :disabled="!optionsLink">
                                      OPTIONS + PRICING
                                    </router-link>
                                </li>
                                <li :class="{ active: reserveActive }">
                                    <router-link :to="reserveLink" tag="button" :disabled="!reserveLink">
                                      RESERVE RV
                                    </router-link>
                                </li>
                            </ul>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
</template>
<script>
export default {
  name: 'Header',
  props: {
    title: String,
    selectActive: Boolean,
    optionsActive: Boolean,
    reserveActive: Boolean,
    selectLink: {
      type: String,
      default: ''
    },
    optionsLink: {
      type: String,
      default: ''
    },
    reserveLink: {
      type: String,
      default: ''
    },
  },
}

</script>
<style>
.component-center-content {
  background-repeat: initial;
}
.component-center-content ul.items {
  padding-left: 0;
}
.component-center-content .flex {
  flex: initial;
}
</style>