<template>

<v-row>
  <v-col md="6">
     <v-row>
        <v-col cols="8">
          <v-text-field solo outlined dense v-model.lazy="Deposit.CardName" prepend-inner-icon="lock" label="Name on card" required :rules="CardNameRules" autocomplete="cc-name"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field solo outlined dense v-model.lazy="Deposit.PostCode" label="ZIP Code" required :rules="PostCodeRules" autocomplete="postal-code" maxlength="10"></v-text-field>
        </v-col>
      </v-row>

      <v-text-field solo outlined dense v-model.lazy="Deposit.CardNumber" prepend-inner-icon="lock" label="Card Number" required :rules="CardNumberRules" autocomplete="cc-number"></v-text-field>
      <v-row>
        <v-col cols="4">
          <v-text-field solo outlined dense v-model="ExpiryMonth" label="Expiry Month" maxlength="2" placeholder="MM" required :rules="ExpiryMonthRules" autocomplete="cc-exp-month"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field solo outlined dense v-model="ExpiryYear" label="Expiry Year" maxlength="2" placeholder="YY" required :rules="ExpiryYearRules" autocomplete="cc-exp-year"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field solo outlined dense v-model.lazy="Deposit.CVV" label="Card Security Code" placeholder="CVV" required :rules="CVVRules" autocomplete="cc-csc"></v-text-field>
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
    name: 'CardPaymentForm',
    props: {
      Deposit: {
        type: Object
      }
    },
    computed: {
      Expiry () {
        // Format into a single date string
        return `20${this.ExpiryYear}-${this.ExpiryMonth}`
      }
    },
    watch: {
      Expiry (val) {
        // copy into the prop object
        this.Deposit.Expiry = val
      },
    },
    data () {
      return {
        CardNameRules: [
          v => !!v || 'Name is required'
        ],
        CardNumberRules: [
          v => !!v || 'Number is required',
          v => /^[0-9]+$/.test(v) || 'Invalid characters',
          v => this.CardNumberValid(v) || 'Invalid Card Number'
        ],
        ExpiryYear: this.Deposit.Expiry.substring(2,4),
        ExpiryYearRules: [
          v => !!v || 'Expiry Year is required',
          v => v.length === 2 || 'Must be 2 digits',
          v => /^[0-9]+$/.test(v) || 'Invalid characters'
        ],
        ExpiryMonth: this.Deposit.Expiry.substring(5,7),
        ExpiryMonthRules: [
          v => !!v || 'Expiry Month is required',
          v => /^[0-9]+$/.test(v) || 'Invalid characters',
          v => v.length === 2 || 'Must be 2 digits',
          v => parseInt(v, 10) < 13 || 'Invalid Month'
        ],
        CVVRules: [
          v => !!v || 'Number is required',
          v => /^[0-9]+$/.test(v) || 'Invalid characters'
        ],
        PostCodeRules: [
          v => !!v || 'ZIP Code is required',
          v => /^[a-zA-Z0-9]+$/.test(v) || 'Invalid characters'
        ],
      }
    },
    methods: {
      CardNumberValid (value) {
        // The Luhn Algorithm
        // Sum each digit from right to left, and double
        // every second digit. If the double exceeds 9,
        // then sum its digits
        // Then modulo 10 of this should be 0 to give a valid number
        let len = value.length;

        let even = true,
            sum = 0,
            digit = 0,
            computed = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

        while (len--) {
            digit = parseInt(value.charAt(len), 10);
            even = !even;
            sum += even ? computed[digit] : digit;
        }

        return sum > 0 && sum % 10 === 0;
      }
    }
};

</script>