import axios from 'axios'
import moment from 'moment'

const tokenGrace = 60;


export default {
  config: {
    baseTokenURL: process.env.VUE_APP_TOKEN_URL,
    tokenRequestBody: { "grant_type": "authorization_code", "scope": process.env.VUE_APP_TOKEN_SCOPE, "code":process.env.VUE_APP_TOKEN_CODE},
    baseAPIURL: process.env.VUE_APP_API_URL,
    staticContentBaseURL: process.env.VUE_APP_STATIC_CONTENT_URL,
    minimumRentalPeriod:  process.env.VUE_APP_MIN_RENTAL_PERIOD,
    maximumRentalPeriod:  process.env.VUE_APP_MAX_RENTAL_PERIOD,
    initialPickupTime: process.env.VUE_APP_PICKUP_TIME,
    initialReturnTime: process.env.VUE_APP_RETURN_TIME,
    rentalDelay:  process.env.VUE_APP_MIN_RENTAL_DELAY
  },
  minimumRentalPeriod: function(){
    return moment.duration(this.config.minimumRentalPeriod)
  },
  maximumRentalPeriod: function(){
    return moment.duration(this.config.maximumRentalPeriod)
  },
  initialPickupTime: function() {
    return this.config.initialPickupTime
  },
  initialReturnTime: function() {
    return this.config.initialReturnTime
  },
  initialPickupDate: function(){
    let duration =  moment.duration(this.config.rentalDelay)
    let date = moment().startOf('day').add(duration)
    let time = moment(this.config.initialPickupTime, 'HH:mm')
    date.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });
    return date.toDate();
  },
  initialDropOffDate: function(){
    let date = moment(this.initialPickupDate()).add(this.minimumRentalPeriod());
    let time = moment(this.config.initialReturnTime, 'HH:mm')
    date.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });
    return date.toDate();
  },
  parseJwt (token) {
    if (!token || typeof token !== 'string') {
      return "";
    }
    let jwt = token.split('.');
    if (jwt.length === 3){
      try {
        let base64Url = jwt[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
      } catch {
        return "";
      }
    }
    return "";
  },
  async refreshToken (){
    let baseURL =  this.config.baseTokenURL;
    this.authToken = await axios.post(`${baseURL}`,this.config.tokenRequestBody)
    .then(response => response.data.access_token)
    .catch((error) => {
      throw new Error(error)
    })
  },
  async getAuthToken () {
    if (!this.authToken) {
      delete(this.jwt)
      await this.refreshToken();
    }
    if (this.authToken && !this.jwt) {
      this.jwt = this.parseJwt(this.authToken);
    }
    if (this.jwt){
      // if it's close to expiring then refresh
      let now = (new Date()).getTime() / 1000;
      if (this.jwt.exp < (now - tokenGrace)){
        await this.refreshToken();
      }
    }
    return "Bearer ".concat(this.authToken);
  },
  async errorMessages () {
    return axios
      .get(`${this.config.staticContentBaseURL}/errors.json`)
      .then(response => response.data)
  },
  async vehicleDescriptions () {
    return axios
      .get(`${this.config.staticContentBaseURL}/vehicles.json`)
      .then(response => response.data)
  },
  async locations () {
    return axios
      .get(`${this.config.staticContentBaseURL}/renting_locations.json`)
      .then(response => response.data)
  },
  async locationsCruise () {
    let cruiseURL = "https://www.cruiseamerica.com"
    if (location && location.origin && location.origin.indexOf('cruiseamerica') != -1){
      cruiseURL = location.origin
    }
    return axios
      .get(`${cruiseURL}/Umbraco/Api/Locations`)
      .then(response => response.data)
  },
  async calenderOffsets () {
    return axios
        .get(`${this.config.staticContentBaseURL}/flexratesoffsets.json`)
        .then(response => response.data)
  },
  async optionDetails () {
    return axios
      .get(`${this.config.staticContentBaseURL}/options.json`)
      .then(response => response.data)
  },
  async addressStates () {
    return axios
      .get(`${this.config.staticContentBaseURL}/address_states.json`)
      .then(response => response.data)
  },
  async countries () {
    return axios
      .get(`${this.config.staticContentBaseURL}/countries.json`)
      .then(response => response.data)
  },
  async openingHours (params) {
    let {location, startDate} = params;
    let token = await this.getAuthToken();
    let baseURL = this.config.baseAPIURL;
    return axios
        .get(`${baseURL}/openhours/${location}`,
            {
              'headers':
                  {
                    'Authorization': token
                  },
              'params': {
                "startDate": startDate
              },
            })
        .then(response => response.data)
  },
  async rates (data) {
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .post(`${baseURL}/rates`, data, { 'headers': { 'Authorization': token } })
    .then(response => response.data)
  },
  async options (ratesRequestId, rateClass){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .get(`${baseURL}/rates/${ratesRequestId}/options/${rateClass}`, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async estimate (optionsRequestId, data){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .post(`${baseURL}/estimate/${optionsRequestId}`, data, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async createReservation (optionsRequestId, data){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .post(`${baseURL}/reserve/${optionsRequestId}`, data, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async cancelReservation (reservationNumber){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .delete(`${baseURL}/reservation/${reservationNumber}`, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async updateReservation (reservationNumber, data){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .put(`${baseURL}/reservation/${reservationNumber}`, data, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async getReservation (reservationNumber){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .get(`${baseURL}/reservation/${reservationNumber}`, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  },
  async getReservationEstimate (reservationNumber, data){
    let token = await this.getAuthToken();
    let baseURL =  this.config.baseAPIURL;
    return axios
    .post(`${baseURL}/reservation/${reservationNumber}/estimate`, data, { 'headers': { 'Authorization': token } })
    .then(response => response.data);
  }
};