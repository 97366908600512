<template>
  <div class="rates">
  <ItineraryComponent></ItineraryComponent>

  <Header title="Review Available RVs" :selectActive="true"></Header>
  <section class="webres-body">
    <v-container>
      <ul class="no-bullets pb-3 my-8">
        <li v-if="pickupLocation"><span class="font-weight-bold">Rental Location:</span> <a :href="pickupLocation.Link">{{ pickupLocation.Description }}, {{ pickupLocation.StateCode }}</a> </li>
        <li><span class="font-weight-bold">Trip Dates:</span> {{ rateQuery.Pickup.DateTime | moment('L') }} - {{ rateQuery.Return.DateTime | moment('L') }}</li>
      </ul>
    <template v-if="loading">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </template>
    <v-card v-for="rate in filteredRates" v-bind:key="rate.Class" class="ratecard mb-4" elevation="0">
      <v-card-text>
        <v-row>
          <v-col lg="9" cols="12" sm="12" class="pl-0 pr-md-0">
            <VehicleDetails :rate="rate" ></VehicleDetails>
          </v-col>
          <v-col lg="3" class="pr-md-0 text-right">
            <template v-if="!ratesResult.rateQuery.FlexibleRates">
              <template v-if="rate.Availability == 'Available'">
                <p class="text-h6 mb-0">Nightly Rate</p>
                <p class="text-h4 mb-0">{{ calcNightlyRate(rate) | price }}</p>
              </template>
            </template>

            <template v-if="rate.Availability != 'Available'">
              <h5 class="text-h6">Limited availability;
               Please call <a href="tel:+18006718042" class="nowrap">800-671-8042</a></h5>
            </template>
            <template v-if="rate.Availability == 'Available'">
              <v-btn color="primary" class="elevation-0 my-6" :loading="loading" :disabled="loading" @click="chooseRate(rate)">Select RV</v-btn>
            </template>
            <br>
            <v-btn v-if="showFlexibleDates" text @click="setFlexible(rate)" class="pr-0"><span class="linkbutton">See Flexible Date Rates</span> <span class="d-sr-only"> for {{ rate.veh.Title }}</span> <v-icon class="pl-1">mdi-calendar</v-icon></v-btn>

          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <p class="text-center minheight" v-show="!loading && !filteredRates.length">
      No rates to show - please change your search criteria and click 'Check Pricing'.
    </p>
  </v-container>
  </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Header from '../components/Header.vue'
import VehicleDetails from '../components/VehicleDetails.vue'
import ItineraryComponent from '../components/ItineraryComponent.vue'
import moment from "moment";

export default {
    name: 'RatesDisplayPage',
    components: {
      Header,
      VehicleDetails,
      ItineraryComponent,
    },
    props: {
    },
    computed: {
      ...mapState({
        loading: 'loading',
        locations: state => state.config.rentingLocations,
        ratesResult: state => state.rates.rates,
        rateQuery: state => state.rates.rateQuery,
        vehicleDescriptions: state => state.config.vehicleDescriptions,
      }),
      ...mapGetters({
        pickupLocation: 'pickupLocation',
      }),
      rentalDuration () {
        let a = moment(this.ratesResult.rateQuery.Return.DateTime.substring(0,10));
        let b = moment(this.ratesResult.rateQuery.Pickup.DateTime.substring(0,10));
        return Math.ceil(a.diff(b,'days', true));
      },
      showFlexibleDates () {
        // Force build not to optimise out this check
        let configured = process.env.VUE_APP_SHOW_FLEXIBLE_DATES
        if (configured == 'true'){
          return true
        }
        return false
      },
    },
    methods: {
      ...mapMutations({
        updateRateQuery: 'rates/updateRateQuery',
        setFlexRateClass: 'rates/setFlexRateClass',
        updateReservation: 'reservation/updateReservation',
        setPickupTime: 'rates/setPickupTime',
        setReturnTime: 'rates/setReturnTime',
      }),
      ...mapActions({
        setDuration: 'rates/setDuration',
      }),
      chooseRate (Rate) {

        // copy Included to Estimated if not populated
        if (Rate.Rental.Distance.Included && !Rate.Rental.Distance.Estimated){
          Rate.Rental.Distance.Estimated = Rate.Rental.Distance.Included
        }
        // Set the rate on the current reservation
        var newResDetails = {
          "Rate": Rate,
          "RateClass": Rate.Class,
          "PackageCode": this.ratesResult.rateQuery.PackageCode,
          // copy the chosen rate itinerary to the current res
          "Pickup": this.ratesResult.rateQuery.Pickup,
          "Return": this.ratesResult.rateQuery.Return,
          "Passengers": this.ratesResult.rateQuery.Passengers,
          // default the distance to the one supplied, the user may change it
          "EstimatedDistance": Rate.Rental.Distance.Estimated
        }
        this.updateReservation(newResDetails);

        if (this.ratesResult.rateQuery.FlexibleRates){
          // Put chosen class on flex rate query
          this.setFlexRateClass(Rate.Class)

          this.$router.push({path: '/flexible-rates'})
          this.$store.dispatch("locations/getLocationOpenHours", {
            location: this.pickupLocation.LocationNumber,
            date: this.pickupDate
          }).then(()=>{
            this.$store.dispatch('rates/getMonthOfRates', this.ratesResult.rateQuery.Pickup.DateTime)
          })
        }
        else {
          this.$router.push({path: '/options'})
        }
      },
      setFlexible (Rate){
        this.$store.commit('rates/recalculateDuration')
        this.$store.commit('rates/setDurationRequested', this.$store.state.rates.duration)
        this.ratesResult.rateQuery.FlexibleRates = true
        this.updateRateQuery({FlexibleRates: true})
        this.chooseRate(Rate)
      },
      calcNightlyRate (rate) {
        if (!rate.Rental.RateOnlyEstimate) return 0
        return rate.Rental.RateOnlyEstimate / this.rentalDuration;
      },
      filterRates () {
        // Hide RVs that can take fewer than the requested passengers
        let self = this;
        if (!this.ratesResult.Rates){
          this.filteredRates = []
          return
        }
        const passengers = self.ratesResult.rateQuery.Passengers || 1;
        this.filteredRates = this.ratesResult.Rates.filter(function(rate) {
          let veh = self.vehicleDescriptions[rate.Class]
          if (!veh) {
            rate.veh = {
              Title: rate.Class
            }
            return true
          }
          rate.veh = veh
          return passengers <= veh.Passengers
        })

        // Sort them into custom order for cruise
        this.filteredRates.sort(function(a, b) {

          const rank = function(rateClass){
            let veh = self.vehicleDescriptions[rateClass]
            if (veh && veh.SortIndex){
              return veh.SortIndex;
            }
            else {
              return self.vehicleDescriptions.length+1;
            }
          }

          const a_rank = rank(a.Class)
          const b_rank = rank(b.Class)
          if (a_rank < b_rank) {
            return -1;
          }
          if (a_rank > b_rank) {
            return 1;
          }
        })
      },

      process_query_strings (q) {
        // Require at least pickup_datetime, return_datetime and pickup_loc e.g.
        // rates?pickup_datetime=2021-04-19T14%3A00&return_datetime=2021-04-22T11%3A00&pickup_state=AK&pickup_loc=ANC
        // also parse return_loc, disc (promo)
        // pickup_state is cruise-specific, just for display

        if (q.pickup_datetime && q.return_datetime){
          this.setPickupTime(q.pickup_datetime.split('T')[1])
          this.setReturnTime(q.return_datetime.split('T')[1])
          this.updateRateQuery({
            from_query_string: true,
            Pickup:{DateTime: q.pickup_datetime+':00'},
            Return:{DateTime: q.return_datetime+':00'}
          })
        }
        if (q.pickup_datetime && q.duration){
          this.setPickupTime(q.pickup_datetime.split('T')[1])
          this.updateRateQuery({
            Pickup:{DateTime: q.pickup_datetime+':00'}
          })
          this.setDuration(parseInt(q.duration, 10))
        }
        if (q.pickup_state){
          this.updateRateQuery({Pickup:{State: q.pickup_state}})
        }
        if (q.pickup_loc){
          this.updateRateQuery({Pickup:{Location: q.pickup_loc }})
        }
        if (q.return_loc){
          this.updateRateQuery({Return:{Location: q.return_loc }})
        }
        else if (q.pickup_loc) {
          // default return loc to match pickup
          this.updateRateQuery({Return:{Location: q.pickup_loc }})
        }
        if (q.disc){
          this.updateRateQuery({ PackageCode: q.disc })
        }
        if (q.passengers){
          this.updateRateQuery({ Passengers: parseInt(q.passengers, 10) })
        }
        if (q.flexible){
          let flexbool = false
          if (q.flexible === 'true'){
            flexbool = true
          }
          this.updateRateQuery({FlexibleRates: flexbool})
        }
      },
    },
    watch: {
      ratesResult: {
        handler(){
          this.filterRates()
        },
        deep: true
      }
    },
    created () {
      // Create rateQuery from url params
      this.process_query_strings(this.$route.query)

      // Clear rateclass before fetching rates in case of returning to this page
      this.updateRateQuery({ RateClass: null })

      this.$store.dispatch('rates/getRates')
      .catch(() => {
        this.$router.push({path: '/itinerary'})
      })
    },
    data () {
      return {
        filteredRates: []
      }
    }
};

</script>
<style>
.v-sheet.ratecard {
  border-top: 0.5px solid #444;
  border-radius: 0;
  padding-top: 24px;
  padding-block: 24px;
}
.nowrap {
  white-space: nowrap;
}
</style>
