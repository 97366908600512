<template>
<div class="component-form-booking-filter" id="sticky-nav">
<div class="container mx-auto">
<div class="row">
<div class="w-11/12 mx-auto">

<v-form ref="form" @focus="focused = true" @blur="focused = false" v-bind:class="[{'flexible-rates': flexibleRates }, 'itinerary-row', 'form-filter']" v-model="valid" v-on:submit.prevent="submit">
    <div class="form-control form-control--state">
      <v-select v-model="pickupState" :items="rentingStates" placeholder="State/Province" item-text="Name" item-value="Code" attach @focus="($event) => {!!$event && $event.target.click()}" @blur="focused = false" outlined dense solo class="form-control" required :rules="requiredField">
        <template v-slot:label>
          State/Province <span class="d-sr-only">Use the up and down arrows or start typing, then use the up and down arrows to select an option from the list</span>
        </template>
      </v-select>
    </div>
    <div class="form-control form-control--city">
      <v-combobox v-model="pickupLocation" :items="filteredLocations" label="City" placeholder="City" item-text="Description" item-value="LocationNumber" attach @focus="focused = true" @blur="focused = false" outlined dense solo required :rules="requiredField"></v-combobox>
    </div>
    <div class="form-control form-control--date">
      <ResponsiveDialog>
        <template v-slot:input="{ on }">
          <div class="form-control flex-row relative">
            <a v-on="on" aria-label="Open the pickup date menu">
              <div class="flex w-full px-4 relative">
                <div class="flex w-full flex-col pr-3">
                  <span class="flex text-3xl font-extrabold text-primary">{{ pickupDate | moment("D") }}</span>
                </div>
                <div class="flex w-full flex-col items-center justify-center uppercase">
                  <span class="flex text-sm leading-4 font-bold text-primary mb-0">{{ pickupDate | moment("MMM") }}</span>
                  <span class="flex text-sm leading-4 font-bold text-primary">{{ pickupDate | moment("ddd") }}</span>
                </div>
              </div>
              <span class="d-sr-only">(Press Enter key to display the calendar below)</span>
            </a>
            <a v-on="on" aria-label="Open the return date menu" v-if="!flexibleRates">
              <div class="flex w-full px-4 relative returndate">
                <div class="flex w-full flex-col pr-3">
                  <span class="flex text-3xl font-extrabold text-primary">{{ returnDate | moment("D") }}</span>
                </div>
                <div class="flex w-full flex-col items-center justify-center uppercase">
                  <span class="flex text-sm leading-4 font-bold text-primary mb-0">{{ returnDate | moment("MMM") }}</span>
                  <span class="flex text-sm leading-4 font-bold text-primary">{{ returnDate | moment("ddd") }}</span>
                </div>
              </div>
              <span class="d-sr-only">(Press Enter key to display the calendar below)</span>
            </a>
          </div>
        </template>
        <template v-slot:picker="{closeModal}">
          <v-sheet elevation="1" height="400" v-if="pickupState && pickupLocation">

                <pick-date-with-opening-times
                  label="Pickup/Return Date"
                  :location.sync="pickupLocation"
                  :pickupDate.sync="pickupDate"
                  :returnDate.sync="returnDate"
                  :min-date="pickupDateMin"
                  :flexibleRates.sync="flexibleRates"
                  @focus="focused = true" @blur="focused = false"
                  :closeModal="closeModal"
                  @closeModal="closeModal"
                >
                </pick-date-with-opening-times>
          </v-sheet>
          <v-sheet v-else class="text-center pa-4">
            Please select a pickup state/province and city first
            <v-btn text color="primary" class="font-weight-bold" @click="closeModal">ОК</v-btn>
          </v-sheet>
        </template>
      </ResponsiveDialog>
    </div>
    <div class="form-control form-control--nights" v-if="flexibleRates">
      <NumberInput label="Nights" v-model="duration" v-bind:required="true" v-bind:min="minDuration" v-bind:max="maxDuration" suffix="Nights"></NumberInput>
    </div>
    <div class="form-control form-control--promo">
      <v-text-field label="Promo Code" v-model="promoCode" outlined dense solo></v-text-field>
    </div>
    <div class="form-control form-control--passenger">
      <NumberInput label="Passengers" v-model="passengers" required :rules="requiredField" :min="1" :max="7" :suffix="passengerSuffix"></NumberInput>
    </div>

    <div class="form-control form-control--submit">
      <v-btn type="submit" @click="submit" color="secondary" :loading="loading" :disabled="loading" class="submit-btn btn justify-between btn-primary w-full md:w-40 min-w-full text-white">

        <span class="text-white font-semibold">CHECK PRICING</span>
        <span class="d-sr-only">(content will update below)</span>
        <span class="text-muted">
          <img src="/images/icon-arrow-right.png" alt="arrow-right">
        </span>

      </v-btn>

      <template v-if="resNo">
        <v-btn color="primary" class="">New Reservation</v-btn>
      </template>
    </div>
</v-form>
</div>
</div>
</div>
</div>
</template>

<script>

import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import ResponsiveDialog from './ResponsiveDialog.vue'
import PickDateWithOpeningTimes from "./PickDateWithOpeningTimes.vue";
import NumberInput from '../components/NumberInput.vue'
import moment from "moment"

import client from 'api-client'
import {pad} from '../tools.js'

export default {
    name: 'ItineraryPage',
    components: {
      NumberInput,
      PickDateWithOpeningTimes,
      ResponsiveDialog,
    },
    props: {
      resNo: new String,
    },
    computed: {
      ...mapGetters({
        getFirstOpenDate: 'locations/getFirstOpenDate',
        getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
        getDuration: 'rates/getDuration'
      }),
      ...mapState({
        loading: 'loading',
        rentingStates: state => state.config.rentingStates,
        locations: state => state.config.rentingLocations,
        rates: state => state.rates.rates,
        rateQuery: state => state.rates.rateQuery,
        flexRatesClass: state => state.rates.flexRatesClass,
        minimumPickupDate: state => state.rates.minimumPickupDate,
        minimumRentalPeriod: state => state.rates.minimumRentalPeriod,
        maximumRentalPeriod: state => state.rates.maximumRentalPeriod,
        pickupTime: state => state.rates.pickupTime,
        returnTime: state => state.rates.returnTime,
      }),
      pickupDateMin () {
        return this.formatDate(this.minimumPickupDate);
      },
      returnDateMin () {
        let pickupDateObject = new Date(this.pickupDate);
        let returnDateMinObject = new Date(this.pickupDate);
        returnDateMinObject.setDate(pickupDateObject.getDate() + this.minimumRentalPeriod.asDays());
        return this.formatDate(returnDateMinObject);
      },
      filteredLocations () {
        let selectedState = this.rateQuery.Pickup.State

        return this.locations.filter(function(loc){
          return loc.StateCode == selectedState
        })
      },
      pickupState: {
        get (){
          return this.rentingStates.find(el=>{
            return el.Code == this.rateQuery.Pickup.State
          })
        },
        set (value){
          if (!value) return
          if (this.pickupLocation && this.pickupLocation.StateCode === value){
            // Don't blank if setting matching state to location
            this.updateRateQuery({Pickup:{State: value}})
          }
          else {
            this.updateRateQuery({Pickup:{State: value, Location: null}})
          }
        }
      },
      pickupLocation:{
          get (){
             return this.locations.find(el=>{
                return el.LocationNumber == this.rateQuery.Pickup.Location
            })
          },
          set (value) {
            if (!value) return
            this.updateRateQuery({Pickup:{Location: value.LocationNumber}})
            // Clear selected RateClass when changing location
            // as we don't know the availability yet
            if (this.rateQuery.FlexibleRates && this.rateQuery.RateClass){
              this.updateRateQuery({RateClass: null })
            }
          }
      },
      flexibleRates: {
        get (){
          return this.rateQuery.FlexibleRates
        },
        set (value){
          this.updateRateQuery({FlexibleRates: value})
        }
      },
      pickupDate:{
          // Note we are convering between two string formats, not involving locale/timezone
          get (){
            return this.rateQuery.Pickup.DateTime.substr(0, 10)
          },
          set (value) {
            // TH-688 If loc closes before pickup, set pickuptime to closing time minus 2 hours
            let openPickup = this.getOpeningTimesForDate(value, this.rateQuery.Pickup.Location)
            let defaultPickupTime = client.initialPickupTime()
            let pickupDateTime = new Date(`${value}T${defaultPickupTime}:00.000Z`)
            let closingTime = "17:00"
            if (openPickup && openPickup[0]){
              closingTime = openPickup[0].EndHour
            }
            let closingDateTime = new Date(`${value}T${closingTime}:00.000Z`)

            if (pickupDateTime > closingDateTime){
              let closingHour = parseInt(closingTime.substring(0,closingTime.indexOf(':')), 10) - 2
              closingHour = pad(closingHour)
              this.setPickupTime(`${closingHour}:00`)
            }
            else {
              this.setPickupTime(defaultPickupTime)
            }

            this.setPickupDate(value);
          }
      },
      returnLocation:{
          get (){
            return this.locations.find(el=>{
                return el.LocationNumber == this.rateQuery.Return.Location
            })
          },
          set (value) {
            if (!value) return
            this.updateRateQuery({Return:{Location: value.LocationNumber}})
          }
      },
      returnDate:{
          get (){
            return this.rateQuery.Return.DateTime.substr(0, 10)
          },
          set (value) {
            this.setReturnDate(value)
          }
      },
      minDuration:{
        get (){
          return Math.ceil(this.minimumRentalPeriod.asDays())
        }
      },
      maxDuration:{
        get (){
          return Math.ceil(this.maximumRentalPeriod.asDays())
        }
      },
      duration: {
        get (){
          return this.getDuration
        },
        set (value){
          let days = parseInt(value, 10);
          this.setDuration(days)
        }
      },
      promoCode: {
        get (){
          return this.rateQuery.PackageCode
        },
        set (value) {
          let uppercased = value.toUpperCase()
          this.updateRateQuery({ PackageCode: uppercased })
        }
      },
      passengers: {
        get (){
          return this.rateQuery.Passengers
        },
        set (value) {
          this.updateRateQuery({ Passengers: value })
        }
      },
      passengerSuffix (){
        if (this.passengers === 1){
          return 'PASSENGER'
        }
        return 'PASSENGERS'
      },
    },
    methods:{
        ...mapMutations({
          updateReservation: 'reservation/updateReservation',
          updateRateQuery: 'rates/updateRateQuery',
          setSeenOptions: 'setSeenOptions',
          setRates: 'rates/setRates',
          clearReservation: 'reservation/clearReservation',
          setPickupTime: 'rates/setPickupTime',
        }),
        ...mapActions({
          clearRates: 'rates/clearRates',
          setDuration: 'rates/setDuration',
          setPickupDate: 'rates/setPickupDate',
          setReturnDate: 'rates/setReturnDate'
        }),
        showDatepicker(tab){
          this.tab = tab
        },
        formatDate(date){
            return date.toISODateOnlyString();
        },
        submit () {
          if (!this.$refs.form.validate()){
            return
          }
          // First clear any existing loaded res
          this.clearReservation();
          this.getRates();
        },
        getRates(){
          // Reset seenOptions as there'll be a new Rate
          this.setSeenOptions(false);

          // If not on our host, put request in a query string
          if (document.location.origin !== process.env.VUE_APP_STATIC_CONTENT_URL){
            let q = {}
            q.pickup_datetime = `${this.pickupDate}T${this.pickupTime}`
            q.flexible = this.flexibleRates
            if (this.flexibleRates){
              q.duration = this.duration
            }
            else {
              q.return_datetime = `${this.returnDate}T${this.returnTime}`
            }
            if (this.pickupState){
              q.pickup_state = this.pickupState.Code
            }
            q.pickup_loc = this.pickupLocation.LocationNumber
            q.disc = this.promoCode
            q.passengers = this.passengers

            let route = this.$router.resolve({path: '/rates', query: q })
            let fullpath = document.location.origin+route.href;
            window.open(fullpath, '_self');
            return;
          }

          if (this.$router.currentRoute.path && this.$router.currentRoute.path.match(/rates.*$/)){
            // Already on rates page, just refresh them
            this.$store.dispatch('rates/getRates')
            .then(() => {
              if (this.rateQuery.FlexibleRates && this.flexRatesClass){
                this.$store.dispatch('rates/getMonthOfRates')
              }
            });
          }
          else {
            // Otherwise go back to rates page to choose class
            this.$router.push({path: '/rates'})
          }
        },
    },
    data () {
      return {
        tab: null,
        pickupDateMenu: false,
        returnDateMenu: false,
        selectedState: '',
        focused: false,
        valid: false,
        requiredField: [
          v => !!v || 'Required'
        ],
      }
    },
    watch: {
      focused (v){
        this.$emit('focus', v)
      },
      pickupDate (){
        if (this.rates && this.rates.rateQuery && this.pickupDate !== this.rates.rateQuery.Pickup.DateTime.substr(0, 10)){
          this.clearRates();
        }
      },
      returnDate () {
        if (this.rates && this.rates.rateQuery && this.returnDate !== this.rates.rateQuery.Return.DateTime.substr(0, 10)){
          this.clearRates();
        }
      },
      promoCode () {
        if (this.rates && this.rates.rateQuery && this.promoCode !== this.rates.rateQuery.PackageCode){
          this.clearRates();
        }
      },
      duration () {
        this.clearRates();
      },
      passengers () {
        if (this.rates && this.rates.rateQuery && this.passengers !== this.rates.rateQuery.Passengers){
          this.clearRates();
        }
      },
      pickupLocation () {
        // set the return location when the pickup location changes
        this.returnLocation = this.pickupLocation;
        if (this.pickupLocation ){
          this.$store.dispatch("locations/getLocationOpenHours", {
            location: this.pickupLocation.LocationNumber,
            date: this.pickupDate
          })
          .then(()=>{
            if (!this.$route.query.return_datetime && !this.$route.query.duration){
              let d1 = this.getFirstOpenDate(this.pickupDate, this.pickupLocation.LocationNumber)
              this.pickupDate = d1.toISODateOnlyString();
              let d2 = this.getFirstOpenDate(this.returnDateMin, this.pickupLocation.LocationNumber)
              this.returnDate = d2.toISODateOnlyString()
            }
          })
        }

        if (this.rates && this.rates.rateQuery && (!this.pickupLocation || this.pickupLocation.LocationNumber !== this.rates.rateQuery.Pickup.Location)){
          this.clearRates();
        }
      },
    },
    created() {
      let q = this.$route.query
      if (q.iata){
        this.updateReservation({VendorID: q.iata})
      }

      var $vm = this
      // TH-1055 has to be bound by script on cruiseamerica.com
      window.SetBookingBarPromo = function(locationCode, startDate, endDate, promoCode) {

        // MM/DD/YYYY HH:MM:SS AM
        // 9/6/2021 12:00:00 AM

        if (locationCode) {
          var loc = $vm.locations.find(loc=>{
            return loc.LocationNumber === locationCode
          })
          var pickupState = $vm.rentingStates.find(state =>{
            return state.Code === loc.StateCode
          })
          $vm.pickupState = pickupState.Code
          $vm.pickupLocation = loc
        }
        if (promoCode) {
          $vm.promoCode = promoCode
        }
        if (startDate) {
          $vm.pickupDate = moment(startDate, 'MM/DD/YYYY hh:mm:ss A').format('YYYY-MM-DD')
        }
        if (endDate) {
          $vm.returnDate = moment(endDate, 'MM/DD/YYYY hh:mm:ss A').format('YYYY-MM-DD')
        }
        window.scrollTo(0,0)
      }
    }
};

</script>
<style>
.itinerary-row .submit-btn {
  justify-content: space-between;
}
.itinerary-row input, .itinerary-row .v-input {
  text-transform: uppercase;
}
.itinerary-row .v-btn
.itinerary .v-main__wrap > .container {
  padding: 0;
}
.itinerary-row .v-text-field__details, .itinerary-row .v-messages {
  min-height: 0;
}
.itinerary-row .v-input, .itinerary-row input {
  font-weight: 600;
}
</style>
