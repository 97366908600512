<template>
  <div>
    <ItineraryComponent></ItineraryComponent>

    <Header title="Flexible Dates" selectLink="/rates" :selectActive="true"></Header>
    <section class="webres-body">
    <v-container>
    <template v-if="loading">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </template>

    <v-toolbar flat color="white" class="calendar-toolbar" v-if="$vuetify.breakpoint.mdAndUp">
      <ClassSwitch :currentMonth="calendarStart"></ClassSwitch>
      <v-spacer></v-spacer>
      <v-chip class="font-italic help-chip" outlined label>
        Select a flexible start date below
      </v-chip>
      <v-spacer></v-spacer>

      <MonthSwitch :currentMonth="title" @next="next" @prev="prev"></MonthSwitch>

    </v-toolbar>

    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col>
        <ClassSwitch :currentMonth="calendarStart"></ClassSwitch>
      </v-col>
      <v-col class="d-flex">
        <v-chip class="font-italic help-chip grow d-flex justify-center" outlined label>
          Select a flexible start date below
        </v-chip>
      </v-col>
      <v-col class="text-right mb-4">
        <MonthSwitch :currentMonth="title" @next="next" @prev="prev"></MonthSwitch>
      </v-col>
    </v-row>
    <v-row class="updown-buttons no-gutters">
      <v-col class="pa-0">
      <v-btn icon @click="prev" aria-label="Previous Month (content will update below)">
        <v-icon large>mdi-menu-up</v-icon>
      </v-btn>
    </v-col>
    </v-row>

  <v-sheet :height="calendarHeight" class="white calendarsheet">
    <v-calendar

      ref="calendar"
      type="month"
      v-model="focus"
      :events="calendarEvents"
      :event-more="false"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
      @click:event="showEvent"
      @change="calendarChange"
      @moved="calendarMoved"
    >
      <template v-slot:day-label="{date}">
        <span class="day-name">{{ date | moment('ddd') }}</span> {{ date | moment('D') }}
      </template>
      <template v-slot:event="{event}">
          <span v-if="event.name == 'CLOSED'" class="d-block closedevent">
            {{ event.name }}
          </span>
          <span v-else-if="!event.rate" class="d-block my-6">
            {{ event.name }}
          </span>
          <button v-else class="rateevent">
            <template v-if="event.rate.Availability == 'Available'">
              <span class="headline font-weight-bold mb-0 price">
                <template v-if="showNightly">
                {{ calcNightlyRate(event.rate) | price }}
                </template>
                <template v-else>
                {{ event.rate.Rental.Estimate | price }}
                </template>
              </span>
              <span class="duration">
                <template v-if="showNightly">
                  per night
                </template>
                <template v-else>
                  total
                </template>
                for <br class="duration-break"> {{ rateDuration(event.rate) }} nights
                <span class="d-sr-only" v-if="rateEventIsSelected(event)">
                  selected
                </span>
              </span>
            </template>
            <div v-if="event.rate.Availability != 'Available'">

              <span class="limited limitedavailability-medium">
                Limited Availability
              </span>
              <span class="limited limitedavailability-large">Call <a href="tel:+18006718042">800-671-8042</a></span>
              <span class="limited limitedavailability-small">Call</span>
            </div>
          </button>
      </template>

    </v-calendar>

    <v-toolbar flat class="calendar-toolbar-bottom">
      <v-spacer></v-spacer>
      <v-btn v-if="showConfirmDatesButton" color="primary" class="elevation-0" :loading="loading" :disabled="loading" @click="chooseRate()">Confirm Dates</v-btn>
    </v-toolbar>

    <v-dialog v-model="selectedOpen" max-width="350"
      :activator="selectedElement"
      attach
    >
      <v-card v-if="modalRate" color="grey lighten-4" flat>
        <v-toolbar color="secondary lighten-5">

          <DateDisplay :pickupDate="ratePickupDate(modalRate)" :returnDate="rateReturnDate(modalRate)"></DateDisplay>

          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="selectedOpen = false" >
            CLOSE
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          <h2 class="text-h6">
            <template v-if="modalRate.Availability == 'Available'">
              {{ calcNightlyRate(modalRate) | price }} per night for {{ rateDuration(modalRate) }} nights
            </template>
            <template v-if="modalRate.Availability != 'Available'">
              Limited Availability
            </template>
          </h2>

          <ul class="pt-4" v-if="modalRate.Availability == 'Available'">
            <li v-if="modalRate.Rental.Liability">
              Liability: {{ modalRate.Rental.Liability | price }}
            </li>
            <li v-if="modalRate.Rental.OneWayFee">
              OneWayFee: {{ modalRate.Rental.OneWayFee | price }}
            </li>
            <li v-if="modalRate.Rental.Distance.Unlimited">
              Unlimited {{mileOrKM}}
            </li>
            <li v-else>
              <template v-if="modalRate.Rental.Distance.Included">{{ modalRate.Rental.Distance.Included }} {{mileOrKM}}s included,</template>
              plus {{ modalRate.Rental.Distance.ExtraRate | price }} per additional {{mileOrKM}}
            </li>
          </ul>
          <template v-if="modalRate.Availability != 'Available'">
          <h5 class="mt-4">Limited availability; Please call <a href="tel:+18006718042">800-671-8042</a> </h5>
          </template>
        </v-card-text>
        <template v-if="modalRate.Availability == 'Available'">
          <v-card-actions class="mx-1">
            <v-btn color="secondary primary" class="elevation-0" @click="chooseRate(modalRate)">Confirm Dates</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-sheet>
</v-container>
  </section>
</div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Header from '../components/Header.vue'
import ClassSwitch from '../components/ClassSwitch.vue'
import MonthSwitch from '../components/MonthSwitch.vue'
import DateDisplay from '../components/DateDisplay.vue'
import ItineraryComponent from '../components/ItineraryComponent.vue'
import moment from "moment";
import {pad} from "../tools.js";
export default {
  name: 'RatesDisplay',
  components: {
    Header,
    ClassSwitch,
    MonthSwitch,
    DateDisplay,
    ItineraryComponent,
  },
  computed: {
     ...mapState({
      loading: 'loading',
      rateQuery: state => state.rates.rateQuery,
      rates: state => state.rates.rates,
      flexibleRates: state => state.rates.flexibleRates,
      flexRatesClass: state => state.rates.flexRatesClass,
      locations: state => state.config.rentingLocations,
      loadingOpeningHours: state => state.locations.loadingOpeningHours,
      reservation: state => state.reservation.reservation,
      errorMessages: state => state.config.errorMessages,
    }),
     ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
    }),
    location () {
      if (!this.rateQuery.Pickup) return
      let locationCode = this.rateQuery.Pickup.Location
      return this.locations.find(el=>{
        return el.LocationNumber == locationCode
      })
    },
    pickupDate () {
      return this.rateQuery.Pickup.DateTime.substr(0, 10)
    },
    returnDate () {
      return this.rateQuery.Return.DateTime.substr(0, 10)
    },
    country () {
      if (this.location){
        return this.location.CountryCode
      }
      return 'US'
    },
    mileOrKM () {
      if (this.country === 'CA'){
        return "KM"
      }
      else {
        return "mile"
      }
    },
    title () {
      if (!this.start){
        return this.calendarStart
      }

      const startYear = this.start.year
      const startMonth = this.monthFormatter(this.start)

      return `${startMonth} ${startYear}`
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'short',
      })
    },
    calendarHeight () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return '500';
      }
      return '600'
    },
    currentFlexRates () {
      let rates = []
      if (this.flexibleRates[this.rateQuery.Pickup.Location] && this.flexibleRates[this.rateQuery.Pickup.Location][this.flexRatesClass]){
        rates = this.flexibleRates[this.rateQuery.Pickup.Location][this.flexRatesClass]
      }
      return rates
    },
    currentRate () {
      return this.rates.Rates.find(el=>{
        return el.Class == this.flexRatesClass
      })
    },
    calendarEvents () {
      // init list with 'closed' day
      let list = []
      list.push(...this.monthlyClosedDays)
      let flexRatesLength = this.currentFlexRates.length
      if (flexRatesLength < 1){
        return list
      }

      let flexRates = this.currentFlexRates
      for (let i = 0; i<flexRates.length; i++){
        let r = this.rateToEvent(flexRates[i]);

        // We want the originl chosen rate to be a different colour
        if (flexRates[i].rateQuery.Pickup.DateTime.substring(0,10) === this.pickupDate){
          r.textColor = 'white'
          r.color = 'primary'
        }
        list.push(r);
      }
      return list;
    },
    monthlyClosedDays (){
      let list = []
      if (!this.start){
        return list
      }
      // if still loading, don't show every day as closed
      if (this.loadingOpeningHours){
        return list
      }
      let monthStart = this.start.date.substring(0,8)
      let monthlength = moment(this.start.date).daysInMonth();

      for (let i = 0; i <= monthlength; i++) {
        // avoid daylight savings issue by iterating the days without Date object help
        let day = pad(i+1)
        let eachDay = `${monthStart}${day}`
        let open = this.getOpeningTimesForDate(eachDay, this.rateQuery.Pickup.Location)
        if (!open || !open.length){
          list.push({
            name: 'CLOSED',
            start: eachDay,
            color: '',
            textColor: 'grey darken-2',
          })
        }
      }
      return list
    },
    showNightly () {
      // Force build not to optimise out this check
      let configured = process.env.VUE_APP_SHOW_NIGHTLY_RATE_ON_CALENDAR
      if (configured == 'true'){
        return true
      }
      return false
    },
    showConfirmDatesButton () {
      let pickupmonth = this.pickupDate.substring(0,7)
      let currentMonth = this.calendarStart.substring(0,7)
      return pickupmonth === currentMonth
    },
  },
  data () {
    return {
      modalVisible: false,
      modalRate: null,
      calendarStart: '',
      start: null,
      today: null,
      focus: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }
  },
  methods: {
    ...mapMutations({
      updateRateQuery: 'rates/updateRateQuery',
      updateReservation: 'reservation/updateReservation',
      setRates: 'rates/setRates'
    }),
    ...mapActions({
      getMonthOfRates: 'rates/getMonthOfRates'
    }),
    showEvent ({ nativeEvent, event }) {
      if (!event.rate){
        return
      }
      // let them click on call directly without a popup on desktop
      if (event.rate.Availability != 'Available' && this.$vuetify.breakpoint.mdAndUp){
        return
      }

      const open = () => {
        this.selectedEvent = event
        this.modalRate = event.rate
        this.selectedElement = nativeEvent.target

        this.$nextTick(() => {
          this.selectedOpen = true, 10
        })
      }

      if (this.modalVisible) {
        this.modalVisible = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    chooseRate (Rate) {
      if (!Rate){
        Rate = this.currentRate
        Rate.rateQuery = this.rateQuery
        Rate.ratesRequestId = this.rates.ratesRequestId
      }
      // Set the rate on the current reservation
      var newResDetails = {
        "Rate": Rate,
        "RateClass": Rate.Class,
        "PackageCode": Rate.rateQuery.PackageCode,
        // copy the chosen rate itinerary to the current res
        "Pickup": Rate.rateQuery.Pickup,
        "Return": Rate.rateQuery.Return,
        "Passengers": this.rateQuery.Passengers,
        // default the distance to the one supplied, the user may change it
        "EstimatedDistance": Rate.Rental.Distance.Estimated
      }
      this.updateReservation(newResDetails)
      // copy the selected rateQuery to the rates object
      let newrates = {
        Rates: [Rate],
        ratesRequestId: Rate.ratesRequestId
      }
      this.setRates(newrates)
      this.updateRateQuery(Rate.rateQuery)
      this.$router.push({path: '/options'})
    },
    getEventColor (event) {
      return event.color
    },
    getEventTextColor (event) {
      return event.textColor
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    setToday () {
      this.focus = this.today
    },
    rateTitle (rate){
      let r = rate.Rates[0];
      if (!r.Rental.Estimate){
        return `${this.rateDuration(rate)} Nights - Limited Availability`
      }
      if (this.showNightly){
        let nightly = this.calcNightlyRate(r)
        nightly = this.$options.filters.price(nightly)
        return `${nightly} per night for ${this.rateDuration(rate)} nights`
      }
      let estimate = this.$options.filters.price(r.Rental.Estimate)
      return `${estimate} for ${this.rateDuration(rate)} nights`
    },
    rateDuration(rate){
      let a = moment(this.rateReturnDate(rate));
      let b = moment(this.ratePickupDate(rate));
      return Math.ceil(a.diff(b,'days', true));
    },
    ratePickupDate(rate){
      return rate.rateQuery.Pickup.DateTime.substring(0,10)
    },
    rateReturnDate(rate){
      return rate.rateQuery.Return.DateTime.substring(0,10)
    },
    calcNightlyRate (rate) {
      if (!rate || !rate.Rental.RateOnlyEstimate) return 0
      return rate.Rental.RateOnlyEstimate / this.rateDuration(rate);
    },
    rateToEvent(rate){
      if (!rate) return
      if (rate.error){
        return {
          name: this.translateError(rate.error),
          allDay: true,
          start: rate.rateQuery.Pickup.DateTime.substring(0,10),
          textColor: 'red',
        }
      }
      let r = rate.Rates[0];
      r.rateQuery = rate.rateQuery;
      r.ratesRequestId = rate.ratesRequestId;
      return {
        name: this.rateTitle(rate),
        rate: r,
        allDay: true,
        start: rate.rateQuery.Pickup.DateTime.substring(0,10),
        textColor: 'grey darken-4',
      }
    },
    rateEventIsSelected(event){
      if (event.rate.rateQuery.Pickup.DateTime === this.rateQuery.Pickup.DateTime){
        return true
      }
      return false
    },
    translateError (error){
      let translatedError = this.errorMessages[error.Number.toString()]
      if (translatedError && translatedError.Text){
        return translatedError.Text
      }
      return error.Text
    },
    calendarChange ({ start }) {
      this.start = start
      this.calendarStart = start.date
    },
    calendarMoved (start){
      // trigger a month of rates
      this.getMonthOfRates(start.date)
    },
    updateCalendarFocus (){
      this.focus = this.rateQuery.Pickup.DateTime.substring(0,10)
    },
  },
  watch: {
    'rateQuery.Pickup.DateTime': 'updateCalendarFocus'
  },
  created () {
    if (!this.rateQuery || !this.rateQuery.Pickup.Location){
      this.$router.push({path: '/itinerary'})
    }
    if (this.location ){
      this.$store.dispatch("locations/getLocationOpenHours", {
        location: this.location.LocationNumber,
        date: this.pickupDate
      })
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
    this.updateCalendarFocus()
  },
};

</script>
<style>
.v-chip.v-chip--outlined.help-chip.v-chip {
  background-color: #f7f7f7 !important;
  border: none;
}
.theme--light.v-calendar-weekly, .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
  border: none;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day {
  overflow: hidden;
  border: none;
  margin: 6px;
  border-radius: 6px;
  background: #f7f7f7;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background: #ebebeb;
}

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday, .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background: transparent;
}
.v-calendar-weekly__head {
  background-color: #ebebeb;
  border-radius: 4px;
  margin: 0 6px 4px 6px;
  padding: 6px 0;
}
.v-outside .v-calendar-weekly__day-label {
  color: #9e9e9e;
}
.v-calendar-weekly__day-label:has(+ .primary){
  color: #fff;
}

.v-calendar-weekly__day-label {
  position: absolute;
  right: 4px;
  line-height: 1.5em;
  z-index: 2;
  font-size: 12px;
  cursor: default;
}
.v-calendar-weekly__day-label .day-name {
  display: none;
}
.v-calendar-weekly__day-label .v-btn--fab.v-size--small {
  height: auto;
}
.calendarsheet {
  margin-bottom: 400px;
}
.v-calendar .v-event {
  height: 100% !important;
  width: 100% !important;
  text-align: center;
  overflow: visible;
  position: absolute;
  display: flex;
  top: 0px;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
}
.rateevent:hover {
  opacity: 0.7;
}
.closedevent {
  transform: translateY(5px) rotate(315deg);
}
.v-calendar .v-event:has(> .closedevent) {
  cursor: initial;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #000;
}
.calendar-toolbar .v-toolbar__content {
  padding-left: 0;
  padding-right: 10px;
}
.calendar-toolbar-bottom .v-toolbar__content {
  padding-right: 6px;
  padding-top: 30px;
}
.rateevent .duration {
  display: block;
  padding-left: 1em;
  padding-right: 1em;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.rateevent .price {
  color: #345c05;
}
.primary .rateevent .price,  .primary .rateevent a {
  color: #fff;
}
.limitedavailability-small, .updown-buttons {
  display: none;
}
.limitedavailability-large {
  display: block;
}
.limitedavailability-medium {
  display: block;
}
@media (max-width: 1300px) {
  .rateevent .headline.price {
    font-size: 1rem !important;
    line-height: 1rem;
  }
  .v-calendar-weekly__day-label {
    margin-top: 0;
  }
  .v-calendar-weekly__day-label .v-btn--fab.v-size--small {
    width: 28px;
  }
}
@media (max-width: 960px) {
  .rateevent .headline {
    font-size: 1.1rem !important;
    line-height: 2em;
  }
  .rateevent .duration {
    font-weight: 600;
    white-space: normal;
    line-height: 1.3em;
    padding: 0;
  }
  .closedevent {
    font-size: 10px;
  }
  .limitedavailability-medium {
    display: block;
    padding: 1em;
    white-space: break-spaces;
  }
  .limitedavailability-large, .limitedavailability-small {
    display: none;
  }
}
@media (max-width: 700px) {
  .limitedavailability-small {
    display: block;
  }
  .limitedavailability-large {
    display: none;
  }
  .limitedavailability-medium {
    display: none;
  }
  .v-application .headline.price {
    font-size: 1rem !important;
    line-height: 1rem;
  }
}
@media (min-width: 480px) and (max-width: 600px){
  .v-application .headline.price {
    font-size: 12px !important;
    line-height: 1rem;
  }
}
@media (min-width: 480px) and (max-width: 700px){
  .rateevent .duration {
    display: none;
  }
}
/* main mobile */
@media screen and (max-width: 480px) {
  .updown-buttons .v-btn {
    padding-left: 18px;
  }
  .limitedavailability-small, .duration-break {
    display: none;
  }
  .limitedavailability-large, .updown-buttons {
    display: block;
  }
  .limitedavailability-medium {
    padding: 0;
    display: inline;
  }
  .rateevent .duration {
    display: inline;
  }
  .rateevent .price {
    vertical-align: middle;
  }
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child,
  .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
    margin-left: 0px;
  }
  .v-calendar-weekly__week,  .v-calendar-weekly {
    display: block;
  }
  .v-toolbar__title,
  .calendarsheet,
  .theme--light.v-calendar-weekly .v-calendar-weekly__day {
    overflow: unset !important;
    width: auto;
    white-space: pre-wrap;
    background: none;
  }
  .theme--light.v-calendar-weekly .v-calendar-weekly__day {
    display: flex;
    align-items: center;
  }
  .theme--light.v-calendar-weekly .v-calendar-weekly__day .v-event {
    background-color: #f7f7f7;
  }
  .theme--light.v-calendar-weekly .v-calendar-weekly__day .v-event, .v-calendar-weekly__day-label, .limited {
    border-radius: 8px;
    padding: 3px 11px;
    min-height: 52px;
  }
  .v-calendar-weekly__day-label .day-name {
    text-align: center;
    display: block;
    font-size: 10px;
    margin: 0;
    padding: 0;
    margin-bottom: -4px;
  }
  .v-calendar-weekly__day-label {
    font-size: 1.1rem;
    line-height: initial;
    padding-top: 10px;
    text-align: center;
    font-weight: bold;
    position: relative;
    min-width: 3em;
  }
  .v-calendar-weekly__head, .v-calendar-weekly__day.v-past,
  .theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
    display: none;
  }
  .v-calendar-weekly__day-label:has(+ .primary){
    background-color: #345c05;
    border-radius: 8px;
  }
  .primary .rateevent .price, .primary .rateevent .limitedavailability-large a {
    color: #fff;
  }
  .v-calendar-weekly__day-label, .v-calendar .v-event {
    position: relative !important;
    width: auto;
  }
  .v-calendar-weekly {
    height: auto;
  }
  .closedevent {
    transform: none;
    margin: auto;
    font-size: 1rem;
  }
  .calendarsheet {
    height: auto !important;
    margin-bottom: auto;
  }
  .v-calendar .v-event {
    justify-content: unset;
    display: inherit;
    text-align: left;
  }
  .v-calendar .v-event button.rateevent {
    text-align: left;;
  }
  .v-application .headline.price {
    font-size: 1.3rem !important;
  }
  .v-application .rateevent .duration {
    font-size: 1rem !important;
  }
}
</style>