<template>
  <div>
  <ItineraryComponent></ItineraryComponent>

  <Header title="Options & Pricing" selectLink="/rates" :optionsActive="true"></Header>
  <section class="webres-body">
    <v-container>
      <template v-if="loading">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </template>

      <v-row no-gutters class="mt-12">
        <v-col v-if="reservation" class="options-panel pa-8 rounded" md="5" cols="12">
          <h2 class="text-h4 mb-6">Options</h2>
          <ul class="no-bullets pb-3">
            <li><span class="font-weight-bold">Location:</span> <a :href="pickupLocation.Link" target="_new">{{ pickupLocation.Description }}, {{ pickupLocation.StateCode }}</a> </li>
            <li><span class="font-weight-bold">Vehicle Selection:</span> {{ vehicleClassTitle }}</li>
            <li><span class="font-weight-bold">Nights Reserved:</span> {{ duration }}</li>
            <li><span class="font-weight-bold">Trip Dates:</span> {{ reservation.Pickup.DateTime | moment('L') }} - {{ reservation.Return.DateTime | moment('L') }}</li>
          </ul>

          <!-- custom option for Miles, which is not really an Option item but has to appear here -->
          <DistanceOption v-if="showDistance" v-on:select-option="onSelectOption" :value="reservation.EstimatedDistance" :steppers="false"></DistanceOption>
          <OptionItem v-for="option in filteredOptions" v-bind:key="option.Code" :option="option" v-on:select-option="onSelectOption"></OptionItem>

        </v-col>

        <v-spacer></v-spacer>
        <v-col v-if="reservation" class="estimate-panel pt-2" md="6" cols="12">
          <EstimateSummary :createdRes="reservation.ReservationNumber"></EstimateSummary>
        </v-col>
      </v-row>
      <TripDetails></TripDetails>

      <v-layout>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="whitebutton elevation-0" :loading="loading" :disabled="loading" @click="submit()">Check Out</v-btn>
      </v-layout>
    </v-container>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import moment from "moment"
import Header from '../components/Header.vue'
import OptionItem from '../components/OptionItem.vue'
import DistanceOption from '../components/DistanceOption.vue'
import TripDetails from '../components/TripDetails.vue'
import EstimateSummary from '../components/EstimateSummary.vue'
import ItineraryComponent from '../components/ItineraryComponent.vue'

export default {
  name: 'OptionsPage',
  components: {
    Header,
    OptionItem,
    DistanceOption,
    TripDetails,
    EstimateSummary,
    ItineraryComponent,
  },
  computed: {
    ...mapState({
      loading: 'loading',
      locations: state => state.config.rentingLocations,
      reservation: state => state.reservation.reservation,
      estimate: state => state.reservation.estimate,
      optionDetails: state => state.config.optionDetails,
      options: state => state.options.options,
      rates: state => state.rates.rates,
    }),
    ...mapGetters({
      pickupLocation: 'pickupLocation',
      vehicleClassTitle: 'vehicleClassTitle',
      showDistance: 'showDistance',
    }),
    filteredOptions () {
      const optionDetails = this.optionDetails;
      const optionsF = this.options.filter(function(opt){
        let detail = optionDetails[opt.Code];
        if (!detail){
          return true;
        }
        return !detail.Hide;
      })
      return optionsF.sort(function(a,b){
        return a.Description.localeCompare(b.Description);
      })
    },
    duration (){
      if (!this.reservation){
        return 0
      }
      // calculate res duration separate to rate duration
      // otherwise changing the itinerary changes the displayed duration
      let a = moment(this.reservation.Return.DateTime.substring(0,10));
      let b = moment(this.reservation.Pickup.DateTime.substring(0,10));
      return Math.ceil(a.diff(b,'days', true));
    },
  },
  methods:{
    ...mapMutations({
      updateReservation: 'reservation/updateReservation',
      setSeenOptions: 'setSeenOptions'
    }),
    ...mapActions({
      getEstimate: 'reservation/getEstimate',
    }),
    getOptions () {

      // Trap undefined objects in case of directly navigating here or page refresh
      if (!this.reservation ){
        this.$router.push({path: '/itinerary'})
        return;
      }
      // Options call requires ratesRequestId
      if (!this.reservation.RateClass || !this.rates || !this.rates.ratesRequestId){
        this.$router.push({path: '/rates'})
        return;
      }

      let rateClass = this.reservation.RateClass;
      let ratesRequestId = this.rates.ratesRequestId;

      let me = this;
      this.$store.dispatch('options/getOptions', {ratesRequestId, rateClass})
      .then(() => {
        // set selected options internal list from Options
        for (let i in me.options){
          let opt = me.options[i];
          if (opt.Quantity){
            me.selectedOptions[opt.Code] = opt.Quantity;
          }
        }
        me.getSelectedOptionsFromReservation()
      })
      .then(()=>{
        this.getEstimate()
      })
      .catch(() => {
        this.$router.go(-1)
      })
    },
    submit () {
      this.setSelectedOptionsOnReservation()
      this.setSeenOptions(true);

      // Determine whether to checkout now or go back to Summary page
      if (this.reservation.ReservationStatus === "new"){
        this.$router.push({path: '/checkout'})
      }
      else {
        this.$router.push({path: `/reservation/${this.reservation.ReservationNumber}`});
      }
    },
    onSelectOption(option){
      this.selectedOptions[option.Code] = option.Quantity;
      this.setSelectedOptionsOnReservation()
    },
    getSelectedOptionsFromReservation () {
      for (let i in this.reservation.Options){
        let option = this.reservation.Options[i];
        if (option.Code){
          this.selectedOptions[option.Code] = option.Quantity;
        }
      }
      // if EstimatedDistance not set
      if (!this.reservation.EstimatedDistance){
        if (this.showDistance){
          this.updateReservation({"EstimatedDistance": 300})
        }
        else {
          this.updateReservation({"EstimatedDistance": 0})
        }
      }
    },
    setSelectedOptionsOnReservation () {
      // Serialise into an array of objects
      let opts = [];
      for (let key in this.selectedOptions){
        opts.push({
          "Code":  key,
          "Quantity": this.selectedOptions[key]
        })
      }
      this.updateReservation({
        "Options": opts
      });
      this.getEstimate()
    },
  },
  created () {
    this.getOptions();
  },
  data () {
    return {
      selectedOptions: []
    }
  }
};
</script>
<style>
  .option {
    border-top: 0.5px solid #000;
    margin-top: 32px;
    padding-top: 12px;
  }
  .option .row {
    margin-bottom: -12px;
  }
  .options-panel {
    background-color: #e9f3fb;
  }
  .options-panel p {
    margin-bottom: 10px;
  }
  .distance-option .v-input {
    min-width: 4em;
  }
</style>