<template>

  <v-date-picker v-model="dates" range no-title scrollable :min="minDate" :max="maxDate" :allowed-dates="allowedDates" :loading="loadingOpeningHours" :disabled="loadingOpeningHours" :show-current="false">
    <v-spacer></v-spacer>
    <v-checkbox v-if="showFlexibleDates" v-model="localFlexibleRates" hide-details class="mr-2 mb-1 mt-0 noborder flex-checkbox" label="My dates are flexible"></v-checkbox>
    <v-spacer></v-spacer>
    <v-btn text color="primary" class="font-weight-bold" @click="closeModal">ОК</v-btn>
  </v-date-picker>

</template>

<script>

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PickDateWithOpeningTimes',
  props: {
    location: {
      type: Object,
    },
    label: {
      type: String,
    },
    pickupDate: {
      type: String,
      required: true
    },
    returnDate: {
      type: String,
      required: true
    },
    minDate: {
      type: String,
    },
    flexibleRates: {
      type: Boolean
    },
    closeModal: {
      type: Function
    },
  },
  data() {
    return {
      localDates: [this.pickupDate, this.returnDate],
      focused: false,
      dateMenu: false,
      localFlexibleRates: this.flexibleRates,
    }
  },
  computed: {
    ...mapState({
      locationOpenHours: state => state.locations.locationOpenHours,
      loadingOpeningHours: state => state.locations.loadingOpeningHours,
      maximumRentalPeriod: state => state.rates.maximumRentalPeriod,
    }),
    ...mapGetters({
      getOpeningTimesForDate: 'locations/getOpeningTimesForDate',
    }),
    dates:{
      get(){
        return this.localDates
      },
      set(v){
        this.localDates = v
        if (v.length > 1){
          this.$emit('update:pickupDate', v[0])
          this.$emit('update:returnDate', v[1])
        }
      },
    },
    prependIcon () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 'event';
      }
      return ''
    },
    showFlexibleDates () {
      // Force build not to optimise out this check
      let configured = process.env.VUE_APP_SHOW_FLEXIBLE_DATES
      if (configured == 'true'){
        return true
      }
      return false
    },
    maxDate(){
      // calculate 'max rental date' only when picking end date
      if (this.localDates.length===1){
        let pickupDateObject = new Date(this.localDates[0])
        let returnDateMaxObject = new Date(this.localDates[0])
        returnDateMaxObject.setDate(pickupDateObject.getDate() + this.maximumRentalPeriod.asDays())
        return returnDateMaxObject.toISODateOnlyString()
      }
      return undefined
    },
  },
  methods: {
    allowedDates: function(val) {
      if (!this.location) return
      return this.getOpeningTimesForDate(val, this.location.LocationNumber)
    },
  },
  watch: {
    pickupDate(v){
      this.localDates[0] = v
    },
    returnDate(v){
      this.localDates[1] = v
    },
    focused (v){
      this.$emit('focus', v)
    },
    dateMenu (v) {
      this.$emit('closeModal', v)
      this.$emit('focus', v)
    },
    flexibleRates (v) {
      this.localFlexibleRates = v

    },
    localFlexibleRates () {
      this.$emit('update:flexibleRates', this.localFlexibleRates)
    },
  }
};
</script>
<style>
.flex-checkbox .v-label {
  font-size: 13px;
  font-weight: 800;
}
</style>