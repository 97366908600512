import client from 'api-client'
import {mergeDeep} from "../../tools.js";
import moment from "moment"

export function DefaultReservation(){
  // Helper function to set shared 'default' new res object
  return {
    Pickup: {
      DateTime: "",
      Location: ""
    },
    Return: {
      DateTime: "",
      Location: ""
    },
    ReservationStatus: "new",
    Source: {
      Code: 'WEB'
    },
    VendorID: "",
    Rate: {},
    RateClass: "",
    Notes: [],
    Options: [],
    Renter: {
      Address: {}
    },
    Deposit: {
      CardNumber: "",
      PostCode: "",
      Expiry: "",
      CVV: "",
      Amount: 300
    }
  }
}

const state = {
  // The currently editing res
  // Include hard-coded res options like Source Code
  reservation: DefaultReservation(),

  estimate: {
    Customer: {
      Total: 0,
      CurrencyCode: '',
      Options: [
        {
          Code: '',
          Quantity: 0,
          CurrencyCode: '',
          Description: '',
          RateExclCharge: 0,
          SubTotal: 0,
          Total: 0,
          Tax: 0
        }
      ],
      TimeCharges: [
        {
          Quantity: 0,
          CurrencyCode: '',
          Description: '',
          RateExclCharge: 0,
          SubTotal: 0,
          Total: 0,
          Tax: 0
        }
      ],
      Distance: {
        RateExclTax: 0,
        RateInclTax: 0,
        Tax: 0,
        Included: 0,
        Unit: '',
        Unlimited: true,
        Estimated: 0,
      }
    }
  },
}

const getters = {
  reservation (state){
    return state.reservation
  },
  combinedTaxCharges (state) {
    // combine the tax charges with options that have 'IncludeInSubTotal' flag
    let optionsIncludedInTaxCharges = state.estimate.Customer.Options.filter(charge => {
      return !charge.IncludeInSubTotal && charge.Code !== 'SLI' && charge.Code !== 'PROM0' && charge.Quantity !== undefined
    })
    if (!state.estimate.Customer.TaxCharges){
      return optionsIncludedInTaxCharges
    }
    return state.estimate.Customer.TaxCharges.concat(optionsIncludedInTaxCharges)
  },
  taxTotal (state, getters){
    if (!state.estimate.Customer.Total || !state.estimate.Customer.TaxCharges){
      return 0
    }
    return Object.values(getters.combinedTaxCharges).reduce((charge, {Total}) => {
      return charge + (Number(Total) || 0)
    }, 0)
  },
  preTaxSubTotal (state, getters) {
    // calculate the pre-tax, pre-security deposit SubTotal
    if (!state.estimate.Customer.Total || !state.estimate.Customer.TaxCharges){
      return 0
    }
    return state.estimate.Customer.Total - getters.taxTotal - 500
  },
  ratePerNight(state, getters){
    return state.reservation.Rate.Rental.RateOnlyEstimate / getters.duration
  },
  duration (state){
    if (!state.reservation.Pickup){
      return 0
    }
    // calculate res duration separate to rate duration
    // otherwise changing the itinerary changes the displayed duration
    let a = moment(state.reservation.Return.DateTime.substring(0,10));
    let b = moment(state.reservation.Pickup.DateTime.substring(0,10));
    return Math.ceil(a.diff(b,'days', true));
  },
}

const mutations = {
  clearReservation(state){
    state.reservation = DefaultReservation()
  },
  setReservation(state, reservation){
    state.reservation = reservation
  },
  updateReservation(state, partialReservation){
    mergeDeep(state.reservation, partialReservation)
  },
  setEstimate (state, estimate){
    state.estimate = estimate
  },
}

const actions = {
  async createReservation({ commit }, data) {
    commit('clearErrors', null, { root: true });
    commit('setLoading', true, { root: true });

    try {
      // Execute reCAPTCHA and get the token
      const recaptchaToken = await this._vm.$executeRecaptcha();

      // Include the reCAPTCHA token in the data
      data.params.RecaptchaToken = recaptchaToken;

      const response = await client.createReservation(data.optionsRequestId, data.params);
      commit('updateReservation', response);
      return Promise.resolve(response);
    } catch (error) {
      commit('setErrors', error, { root: true });
      return Promise.reject(error);
    } finally {
      commit('setLoading', false, { root: true });
    }
  },
  cancelReservation({commit}, reservationNumber){
    commit('setLoading', true, { root: true });
    return client
      .cancelReservation(reservationNumber)
      .then((response) => {
        commit('updateReservation', {
          "ReservationStatus":"cancelled"
        });
        return Promise.resolve(response)
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  editReservation({commit}, data){
    commit('setLoading', true, { root: true });
    return client
      .updateReservation(data.reservationNumber, data.params)
      .then((response) => {
        commit('updateReservation', response);
        return Promise.resolve(response)
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getReservation({dispatch, commit}, reservationNumber){
    commit('setLoading', true, { root: true });
    return client
      .getReservation(reservationNumber)
      .then((reservation) => {
        commit('setReservation', reservation);
        /* auto get a res estimate, as there won't be one from $state */
        if (reservation.ReservationStatus === 'open'){
          dispatch('getReservationEstimate', {reservationNumber: reservationNumber});
        }
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getEstimate({commit, state}){
    let optionsRequestId = this.getters['options/optionsRequestId'];
    let RateClass = state.reservation.Rate.Class;
    let Options = state.reservation.Options.filter(opt => opt.Quantity > 0);
    let EstimatedDistance = state.reservation.EstimatedDistance;
    if (!optionsRequestId){
      // Avoid error when calling out-of-flow
      // i.e. during template development
      return;
    }
    commit('setLoading', true, { root: true });
    return client
      .estimate(optionsRequestId, {RateClass, Options, EstimatedDistance})
      .then((estimate) => {
        commit('setEstimate', estimate);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
  getReservationEstimate({commit}, data){
    commit('setLoading', true, { root: true });
    // Always update the estimate to match the res on the state
    let res = this.state.reservation;
    let estimateParams = {
      Voucher: res.Voucher,
      RateClass: res.RateClass,
      EstimatedDistance: res.EstimatedDistance
    };
    return client
      .getReservationEstimate(data.reservationNumber, estimateParams)
      .then((estimate) => {
        commit('setEstimate', estimate);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
      /* auto get a res estimate, as there won't be one from $state */
      .finally(() => {
        commit('setLoading', false, { root: true })
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}