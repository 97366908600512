import client from 'api-client'

const state = {
  countries: [],
  addressStates: [],
  rentingStates: [],
  rentingLocations: [],
  errorMessages: [],
  vehicleDescriptions: {},
  optionDetails: {},
}

const getters = {
}

const mutations = {
  setCountries (state, countries) {
    state.countries = countries
  },
  setAddressStates (state, addressStates) {
    state.addressStates = addressStates
  },
  setRentingLocations (state, locations) {
    state.rentingStates = []
    state.rentingLocations = []
    let USstates = []
    let CAstates = []
    let locs = Object.values(locations)
    locs.forEach(function(loc){
      // Compile state list
      let newstate = {
          "Code": loc.stateCode,
          "Name": loc.state,
          "Currency": "USD"
      }
      if (loc.countryCode === "CAN"){
        newstate.Currency = "CAD";
        if (CAstates.indexOf(newstate) === -1){
          CAstates.push(newstate)
        }
      }
      else {
        if (USstates.indexOf(newstate) === -1){
          USstates.push(newstate)
        }
      }
      let phone = ''
      if (loc?.contact?.rentals?.phone){
        phone = loc.contact.rentals.phone
      }
      // Compile loc list
      let newloc = {
        "Name": loc.name,
        "Description": loc.name,
        "CountryCode": loc.countryCode,
        "StateName": loc.state,
        "LocationNumber": loc.code,
        "StateCode": loc.stateCode,
        "Type": loc.type,
        "Currency": newstate.Currency,
        "Address":
        {
          "Company": loc.company,
          "Street": loc.street,
          "CityState": loc.cityState,
          "Postal": loc.postal,
          "Phone": phone,
        },
        "Link": `https://www.cruiseamerica.com${loc.url}`,
      }
      state.rentingLocations.push(newloc)
    })
    const sortbyname = function(a, b) {
      if (a.Name > b.Name) {
        return 1;
      }
      if (a.Name < b.Name) {
        return -1;
      }
      return 0;
    }
    const sortbytype = function(a, b) {
      if (a.Type === 'H' && b.Type !== 'H') {
        return -1;
      }
      if (a.Type !== 'H' && b.Type === 'H') {
        return 1;
      }
      return 0;
    }
    state.rentingLocations.sort(sortbyname)
    state.rentingLocations.sort(sortbytype)
    USstates.sort(sortbyname)
    CAstates.sort(sortbyname)
    state.rentingStates = USstates.concat(CAstates)

  },
  setErrorMessages (state, errorMessages){
    state.errorMessages = errorMessages
  },
  setVehicleDescriptions (state, vehicleDescriptions){
    state.vehicleDescriptions = vehicleDescriptions
  },
  setOptionDetails (state, optionDetails){
    state.optionDetails = optionDetails
  },
}

const actions = {
  countries ({ commit }) {
    return client
      .countries()
      .then((countries) => {
        commit('setCountries', countries);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      })
  },
  addressStates({commit}){
    return client
      .addressStates()
      .then((file) => {
        if (!file.States){
          let error = {"response":{"data":{"Text":"badly formed address_states.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setAddressStates', file.States);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  rentingLocations ({dispatch, commit}) {
    return client
      .locations()
      .then(locations => {
        if (!locations){
          let error = {"response":{"data":{"Text":"badly formed renting_locations.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setRentingLocations', locations);
          dispatch('locationsCruise');
          return Promise.resolve()
        }, error => {
          commit('setErrors', error);
          return Promise.reject(error)
        })
    },
    locationsCruise ({commit}) {
      return client
        .locationsCruise()
        .then(locations => {
          if (!locations){
            let error = {"response":{"data":{"Text":"badly formed cruise locations api response"}}};
            commit('setErrors', error);
            return Promise.reject(error)
          }
          commit('setRentingLocations', locations);
          return Promise.resolve()
        }, error => {
          commit('setErrors', error, { root: true });
        })
  },
  errorMessages({commit}){
    return client
      .errorMessages()
      .then((errorMessages) => {
        if (!errorMessages.Errors){
          let error = {"response":{"data":{"Text":"badly formed errors.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
      commit('setErrorMessages', errorMessages.Errors);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  vehicleDescriptions({commit}){
    return client
      .vehicleDescriptions()
      .then((vehicleDescriptions) => {
        if (!vehicleDescriptions.Vehicles){
          let error = {"response":{"data":{"Text":"badly formed vehicles.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setVehicleDescriptions', vehicleDescriptions.Vehicles);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
  getOptionDetails({commit}){
    return client
      .optionDetails()
      .then((optionDetails) => {
        if (!optionDetails.Options){
          let error = {"response":{"data":{"Text":"badly formed options.json"}}};
          commit('setErrors', error, { root: true });
          return Promise.reject(error)
        }
        commit('setOptionDetails', optionDetails.Options);
        return Promise.resolve()
      }, error => {
        commit('setErrors', error, { root: true });
        return Promise.reject(error)
      });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}