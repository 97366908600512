<template>
  <v-text-field class="numberinput" v-model="Quantity" type="number" :label="label" :required="required" :rules="rules" :min="min" :max="max" step="1" @blur="limit" outlined dense :suffix="suffix"></v-text-field>
</template>
<script>

export default {
  name: 'NumberInput',
  props: {
    steppers: Boolean,
    label: String,
    suffix: String,
    required: Boolean,
    value: Number,
    max: Number,
    min: Number,
    rules: Array,
  },
  methods: {
    limit () {
      if (this.Quantity > this.max){
        this.Quantity = this.max
      }
      if (this.Quantity < this.min){
        this.Quantity = this.min
      }
    }
  },
  watch: {
    value(v){
      this.Quantity = v;
    },
    Quantity () {
      // Send the quantity to the parent
      this.$emit('input', parseInt(this.Quantity, 10));
    },
  },
  data () {
    return {
      Quantity: this.value || this.min || 0,
    }
  }
};
</script>
<style>
/* workaounrd solo label disappearing; remove solo then hide active label instead */
.numberinput.v-text-field--outlined.v-input--dense .v-label--active {
  display: none;
}
.v-application--is-ltr .numberinput.v-text-field--outlined legend {
  width: 0px !important;
}
</style>